import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import ButtonToggle from 'components/button-toggle'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown'
import { CheckboxNoHooks } from 'components/checkbox'
import { ReactComponent as IconSave } from 'assets/icon-save-white.svg'
import { TEAM_REPORT_TYPE, getCompanyAndBUNames } from '../constants'

const TeamReportsLinkNew = ({ unlinkedReports, onLinkReports, onCancel }) => {
  const [reportsToLink, setReportsToLink] = useState([])

  const dropdownOptions = unlinkedReports.map((r) => ({
    ...r,
    value: r._id,
    label: r.name,
  }))

  const linkReports = () => {
    onLinkReports(
      unlinkedReports.filter((el) => reportsToLink.includes(el._id.toString()))
    )
    setReportsToLink([])
  }

  return (
    <>
      <div className="teams__bordered">
        <div className="general-label">Embed Reports</div>
        <Dropdown
          options={dropdownOptions}
          selectedItems={reportsToLink}
          onChange={setReportsToLink}
          multiSelect={true}
          className="half-width"
          defaultOptionText={`Select Embed Reports`}
          optionRenderer={(option) => (
            <div key={option.value} className="align-row account-dropdown">
              <CheckboxNoHooks
                label={option.label}
                isChecked={reportsToLink.indexOf(option.value) > -1}
                hint={
                  option.company ? (
                    <span data-testid="hint-element">
                      {getCompanyAndBUNames(option)}
                    </span>
                  ) : null
                }
              />
            </div>
          )}
          selectAll={true}
          disabled={dropdownOptions.length === 0}
        />
      </div>
      <div className="teams__bordered">
        <div className="campaign-exclusions-create-actions">
          <Button
            className="campaign-exclusions-create-actions__save"
            value={
              <div className="display-flex">
                <IconSave />
                <div className="campaign-exclusions-create-actions__save__label">
                  SAVE
                </div>
              </div>
            }
            secondaryGreen={true}
            disabled={reportsToLink.length === 0}
            onClick={linkReports}
          />
          <Button
            className="campaign-exclusions-create-actions__cancel"
            value="CANCEL"
            secondaryGray={true}
            onClick={onCancel}
          />
        </div>
      </div>
    </>
  )
}

TeamReportsLinkNew.propTypes = {
  unlinkedReports: PropTypes.array.isRequired,
  onLinkReports: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default TeamReportsLinkNew
