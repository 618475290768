import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import RoundRectangleButton from 'components/round-rectangle-button'
import Button from 'components/button'
import Modal from 'components/modal'
import { ReactComponent as IconFilter } from 'assets/filters.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_minus_red.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import 'modules/companies/subsections/reports/style.scss'

import TeamReportsTable from './team-reports-table'
import TeamReportFilters from './team-reports-filters'
import TeamReportsLinkNew from './team-reports-link'

const TeamReports = ({
  team,
  isViewMode,
  onReportsDeleted,
  onReportsLinked,
  showLinkReportsView,
  setShowLinkReportsView,
  isSuperAdmin,
  teamUsers,
}) => {
  const [filteredReports, setFilteredReports] = useState([])
  const [selectedReports, setSelectedReports] = useState([])
  const [filtersOpened, setFiltersOpened] = useState(false)
  const [reportsToDelete, setReportsToDelete] = useState([])
  const [filters, setFilters] = useState({ ...FILTERS_DEFAULT_STATE })

  useEffect(() => {
    team.reports && applyFilters()
  }, [team.reports])

  const applyFilters = (newFilters) => {
    const filtersToApply = newFilters || filters
    const { name, reportType, users, status } = filtersToApply
    let filteredReports = team.reports.filter((report) => {
      const isNameMatch = report.name.toLowerCase().includes(name.toLowerCase())
      const isReportTypeMatch =
        !reportType.length || reportType.includes(report.type)
      const isUserMatch = !users.length || users.includes(report.owner._id)
      const isStatusMatch =
        !status.length ||
        (status.includes('active') && report.active) ||
        (status.includes('inactive') && !report.active)
      return isNameMatch && isReportTypeMatch && isUserMatch && isStatusMatch
    })
    if (!isSuperAdmin) {
      filteredReports = filteredReports.map((report) => {
        const usersToShow = report.users.filter(({ _id }) =>
          teamUsers.find((u) => u._id === _id)
        )
        return { ...report, users: usersToShow }
      })
    }
    setFilteredReports(filteredReports)
  }

  const clearFilters = () => {
    const newFilters = { ...FILTERS_DEFAULT_STATE }
    setFilters(newFilters)
    applyFilters(newFilters)
  }

  const _renderHeader = () => (
    <div
      data-cy="reports-section-header"
      className="reports-headers reports-section__header"
    >
      {showLinkReportsView ? 'Link Report' : 'Manage Reports'}
      <RoundRectangleButton
        className={cx('margin-left-16', {
          'filter-active': filtersOpened,
          disabled: showLinkReportsView || !team.reports?.length,
        })}
        contentClassName="filter-icon"
        onClick={() => {
          setFiltersOpened(!filtersOpened)
          selectedReports.length && setSelectedReports([])
          if (filtersOpened) {
            clearFilters()
          }
        }}
        contentRender={() => (
          <IconFilter
            className={filtersOpened ? 'fill-white' : 'fill-light-blue'}
          />
        )}
      />
    </div>
  )

  const _renderRemoveSelectedBtn = () => (
    <div className="teams__bordered">
      <Button
        compact
        secondaryRed
        disabled={selectedReports.length === 0}
        value={
          <div className="align-row gap-10 align-items-center">
            <DeleteIcon width={18} height={18} />
            <span>Remove Selected</span>
          </div>
        }
        onClick={() => setReportsToDelete([...selectedReports])}
        className="fixed-height"
      />
    </div>
  )

  const onLinkReports = (reportsToLink) => {
    onReportsLinked([...reportsToLink])
    setShowLinkReportsView(false)
  }

  const onUnlinkReports = () => {
    onReportsDeleted(reportsToDelete)
    setSelectedReports([])
    setReportsToDelete([])
  }

  return (
    <div className="reports-section">
      {_renderHeader()}

      {showLinkReportsView ? (
        <TeamReportsLinkNew
          unlinkedReports={team.unlinkedReports || []}
          onLinkReports={onLinkReports}
          onCancel={() => setShowLinkReportsView(false)}
        />
      ) : filtersOpened ? (
        <TeamReportFilters
          reports={team.reports || []}
          filter={filters}
          setFilter={setFilters}
          onApplyFilter={() => applyFilters()}
        />
      ) : (
        _renderRemoveSelectedBtn()
      )}

      <TeamReportsTable
        reports={filteredReports}
        selectedReports={selectedReports}
        setSelectedReports={setSelectedReports}
        setReportsToDelete={setReportsToDelete}
        disableActions={isViewMode}
        viewMode={isViewMode}
      />

      <Modal
        opened={reportsToDelete.length > 0}
        icon={<WarningIcon />}
        contentSeparator={true}
        isContentPadded={true}
        rightAlignButtons={true}
        heading="Unlink Report"
        className="teams__unlink-modal"
        button={<Button value="Confirm" green onClick={onUnlinkReports} />}
        buttonSecondary={
          <Button
            value="Cancel"
            onClick={() => setReportsToDelete([])}
            secondaryGray
          />
        }
      >
        <p>
          You are about to unlink a report from this team. This action cannot be
          undone.
        </p>
        <p>Click the Confirm button to continue.</p>
      </Modal>
    </div>
  )
}

TeamReports.propTypes = {
  team: PropTypes.object.isRequired,
  isViewMode: PropTypes.bool.isRequired,
  onReportsDeleted: PropTypes.func.isRequired,
  onReportsLinked: PropTypes.func.isRequired,
  showLinkReportsView: PropTypes.bool.isRequired,
  setShowLinkReportsView: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  teamUsers: PropTypes.array.isRequired,
}

const FILTERS_DEFAULT_STATE = {
  name: '',
  reportType: [],
  users: [],
  status: [],
}

export default TeamReports
