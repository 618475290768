import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

// Components
import Button from 'components/button'
import LineDivider from 'components/line-divider'
import { Dropdown } from 'components/dropdown'

// Utils
import { alert } from '@decision-sciences/qontrol-common'
import { ReactComponent as IconSave } from 'assets/icon-save-white.svg'
import { ALERT_DETAILS_MAP } from './cell'

const { ALERT_VALUE_MAP } = alert

const allSameValue = (alerts, property) => {
  return alerts.every((alert) => alert[property] === alerts[0][property])
}

const AlertEditSection = ({
  alerts,
  onSave,
  onCancel,
  canEditValue,
  isLinked,
  hasToggleAlertAccess,
}) => {
  const [alertsEditedProps, setAlertsEditedProps] = useState({})
  const [saveDisabled, setSaveDisabled] = useState(true)

  const defaultValueText = useMemo(() => {
    return allSameValue(alerts, 'alertValue')
      ? ALERT_VALUE_MAP[alerts[0].alertValue.toUpperCase()]
      : '<varies>'
  }, [alerts])

  const defaultStatusText = useMemo(() => {
    return allSameValue(alerts, 'active')
      ? alerts[0].active
        ? 'On'
        : 'Off'
      : '<varies>'
  }, [alerts])

  const valueIcon = useMemo(() => {
    const value = alertsEditedProps.value || alerts[0].alertValue
    return defaultValueText !== '<varies>'
      ? ALERT_DETAILS_MAP[value]?.icon
      : null
  }, [alertsEditedProps.value, defaultValueText, alerts])

  const canEditStatus = useMemo(() => {
    return !alerts.some((alert) => !alert.active || alert.required || isLinked)
  }, [alerts])

  const onChangeProperty = (value, property) => {
    setAlertsEditedProps((prevProps) => ({ ...prevProps, [property]: value }))
    setSaveDisabled(false)
  }

  const onSaveClicked = () => {
    onSave(alertsEditedProps)
  }

  const _renderValueField = (option) => (
    <div key={option.value} className="display-flex padding-10">
      {ALERT_DETAILS_MAP[option.value].icon}
      <div className="margin-left-10 align-center">
        {ALERT_VALUE_MAP[option.value.toUpperCase()]}
      </div>
    </div>
  )

  return (
    <div className="form__section__body margin-bottom-20 padding-0 alert-filters alert-edit-section">
      <h3 className="generic-heading generic-heading--no-margin padding-15">
        Edit Selected Alerts
      </h3>
      <LineDivider />
      <div className="display-flex padding-15">
        {canEditValue && (
          <div className="display-flex width-50">
            <Dropdown
              label="Value"
              defaultOptionText={defaultValueText}
              defaultState={alertsEditedProps.value}
              icon={valueIcon}
              options={Object.values(ALERT_VALUE_MAP).map((key) => ({
                label: key,
                value: key,
              }))}
              optionRenderer={_renderValueField}
              onChange={(val) => onChangeProperty(val, 'value')}
            />
          </div>
        )}
        {hasToggleAlertAccess && canEditStatus && (
          <div className="display-flex width-50 margin-left-16">
            <Dropdown
              label="Status"
              defaultOptionText={defaultStatusText}
              defaultState={alertsEditedProps.active}
              options={[
                { label: 'On', value: true },
                { label: 'Off', value: false },
              ]}
              onChange={(val) => onChangeProperty(val, 'active')}
            />
          </div>
        )}
      </div>
      <LineDivider />
      <div className="campaign-exclusions-create-actions padding-15">
        <Button
          className="campaign-exclusions-create-actions__save"
          value={
            <div className="display-flex">
              <IconSave />
              <div className="campaign-exclusions-create-actions__save__label">
                SAVE
              </div>
            </div>
          }
          disabled={saveDisabled}
          secondaryGreen={true}
          onClick={onSaveClicked}
        />
        <Button
          className="campaign-exclusions-create-actions__cancel"
          value="CANCEL"
          secondaryGray={true}
          onClick={onCancel}
        />
      </div>
    </div>
  )
}

AlertEditSection.propTypes = {
  alerts: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  canEditValue: PropTypes.bool.isRequired,
  isLinked: PropTypes.bool.isRequired,
  hasToggleAlertAccess: PropTypes.bool.isRequired,
}

export default AlertEditSection
