import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  utils,
  alert,
  accounts,
  entityStatus,
} from '@decision-sciences/qontrol-common'

/* Store */
import { useStore } from 'store'
import useSession from 'modules/session'
import { editLockContext } from 'contexts/edit-lock'

/* Components */
import Button from 'components/button'
import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import Table from 'components/table/beta'
import Toggle from 'components/toggle'
import Modal from 'components/modal'
import StickyFooter from 'components/sticky-footer'
import { AccountIcon } from 'components/account-icon'
import AlertsBulkEdit from 'modules/alerts/alert-bulk-edit'
import Filters, { FilterSection } from 'components/filters'
import DropdownWithSubsections from 'components/dropdown-with-subsections'

/* Custom Hooks */
import { useEffectOnUpdate } from 'components/utils/custom-hooks'

/* Utils */
import isEqual from 'lodash.isequal'
import { getTooltipList } from 'components/utils/tooltip'
import { renderOwnerCell } from 'components/utils/owner'

/* Actions */
import { deleteAlert, getAlertFilters } from 'modules/alerts/actions'
import {
  resetFilterSort,
  saveFilter,
  saveSort,
  savePagination,
} from 'modules/table-filter-sort/actions'

/* Constants */
import { useAccess, PERMISSIONS, PERMISSION_TYPES } from 'hooks/access'
import {
  defaultFilterSortConfig,
  getLocalStorageTableFilterSort,
} from 'modules/table-filter-sort/constants'
import { ACCOUNT_ORDER } from 'modules/accounts/common'

/* Assets */
import lockIcon from 'assets/icon_lock.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { RadioNoHooks } from '../../components/radio'

const { ENTITY_STATUS_LABEL, ENTITY_STATUS_OPTIONS } = entityStatus
const { getCommonFields } = utils.object
const { compareIgnoreCase } = utils.string
const {
  ALERT_TYPES,
  ALERT_TIME,
  ALERT_TYPES_MAP,
  ALERT_GRANULARITY,
  ALERT_GRANULARITY_LABELS,
} = alert
const { ACCOUNT_TYPE_NAMES } = accounts

const AlertsModule = ({
  list,
  total,
  company,
  updateStatus,
  dispatch,
  allCompanies,
  teams,
  alertCategories,
  tableContainer,
  filterLoading,
  fetchData,
}) => {
  const { state } = useStore()
  const limitWidth = window.screen.width <= 1440 ? 40 : 100
  const { bulkEditList, navigateOnAction } = state.alerts
  const [loading, setLoading] = useState(filterLoading)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [selectedAlerts, setSelectedAlerts] = useState(bulkEditList)
  const [bulkEdit, setBulkEdit] = useState(bulkEditList.length || false)
  const [loadingAlerts, setLoadingAlerts] = useState({})
  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const navigate = useNavigate()
  const [, user] = useSession()
  const isAdmin = user.isSuperAdmin

  const hasViewAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasToggleAlertAccess = useAccess({
    feature: PERMISSIONS.ASSIGNED_ALERTS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.DELETE,
  })
  const hasAlertTriggerAccess = useAccess({
    feature: PERMISSIONS.ACTIVE_ALERTS,
    type: PERMISSION_TYPES.READ,
  })

  /** Edit locks */
  const { setEntityType, locks, EDIT_LOCK_ENTITIES } =
    useContext(editLockContext)

  /** Tell the Edit Lock context what entity we're currently on */
  useEffect(() => {
    setEntityType(EDIT_LOCK_ENTITIES.ALERT)

    /** It's important to clear the type upon leaving the page */
    return () => {
      setEntityType(null)
    }
  }, [setEntityType])

  useEffectOnUpdate(() => {
    if (!bulkEdit) {
      fetchData()
    }
  }, [bulkEdit])

  /** Disable loader only after the locks have been found for the entities */
  useEffect(() => {
    setLoading(!locks)
  }, [locks])

  useEffect(() => {
    setLoading(filterLoading)
  }, [filterLoading])

  const onChangeTableView = ({ pageIndex, pageSize, filter, sort }) => {
    const newFilter =
      getLocalStorageTableFilterSort()?.filterSort[tableContainer].filter ||
      defaultFilterSortConfig.filterSort[tableContainer].filter
    saveFilter(dispatch, tableContainer, {
      ...newFilter,
      keyword: String(filter || '').trim(),
    })

    if (sort) {
      sortChanged(sort)
    }

    savePagination(dispatch, tableContainer, {
      page: pageIndex,
      size: pageSize,
    })
  }

  const columns = [
    {
      header: '',
      id: 'actions',
      cell: (cell) => {
        const { required, _id, owner } = cell.row.original

        /** Edit lock */
        const locked = locks?.[_id]

        const editDisabled = required && !(isAdmin || hasViewAccess)
        if (bulkEdit) {
          return null
        }
        // Non-admins only have access to edit alerts created by them
        const hasEdit = isAdmin || (hasEditAccess && owner._id === user._id)
        const viewAccess = !isAdmin && hasViewAccess && (!hasEdit || required)
        return (
          <div className="table__actions">
            <div
              className={cx('', {
                table__edit: hasEdit && !locked,
                table__view: viewAccess && !locked,
                'table__edit--disabled': editDisabled && !locked,
                table__lock: locked,
              })}
              onClick={() => {
                if (!locked && !hasEdit && viewAccess) {
                  return navigate(`/alerts/${_id}/view`)
                }
                !locked && !editDisabled && navigate(`/alerts/${_id}`)
              }}
            />
          </div>
        )
      },
      tooltip: (row) => {
        /** Edit lock */
        const locked = locks?.[row._id]

        return locked
          ? getTooltipList(locked.tooltipHeader, [locked.tooltipText])
          : null
      },
      minSize: 35,
      maxSize: 35,
    },
    {
      id: 'name',
      header: 'Name',
      accessorFn: (row) =>
        `${row.name?.substring(0, limitWidth)}${
          row.name?.length >= limitWidth ? '...' : ''
        }`,
      tooltip: (row) => {
        return row.name?.length >= limitWidth
          ? getTooltipList('Name', [row.name])
          : null
      },
      minSize: 180,
      maxSize: 180,
      sortingFn: 'name',
    },
    {
      id: 'publishersAmount',
      header: 'Publisher',
      cell: (cell) => (
        <div className="table__icons">
          {cell.row.original.selectedAccounts
            .sort((a, b) => ACCOUNT_ORDER[a] - ACCOUNT_ORDER[b])
            .map((publisher) => {
              return <AccountIcon key={publisher} accountType={publisher} />
            })}
        </div>
      ),
      accessorFn: (row) => row.publishersAmount,
    },
    {
      id: 'category',
      header: 'Alert Category',
      accessorFn: (row) => row.category.name,
    },
    {
      id: 'frequency',
      header: 'Frequency',
      cell: (cell) => {
        const { recurrence } = cell.row.original
        return (
          <div className="align-row" style={{ width: '100%' }}>
            {recurrence
              ? `Every ${recurrence.amount} ${recurrence.frequency}`
              : ''}
          </div>
        )
      },
      accessorFn: (row) => row.frequency,
      sorType: 'numeric',
    },
    {
      id: 'template',
      header: 'Associated Template',
      accessorFn: (row) =>
        row?.template && row?.alertType === ALERT_TYPES_MAP.PERFORMANCE
          ? `${row.template.name?.substring(0, 40)}${
              row.template.name?.length >= 40 ? '...' : ''
            }`
          : '',
      tooltip: (row) => {
        return row?.template?.name?.length >= 40
          ? getTooltipList('Associated Template', [row.template.name])
          : null
      },
      sorType: 'alphanumeric',
    },
    {
      id: 'ownerFullName',
      header: 'Owner',
      cell: renderOwnerCell,
      tooltip: (row) => {
        if (!row.owner.active) {
          return 'Inactive User'
        }
      },
      sorType: 'alphanumeric',
    },
    {
      id: 'active',
      header: 'Status',
      cell: (cell) => {
        const { _id, active, owner } = cell.row.original

        const locked = locks?.[_id]

        return (
          <div className="align-row" style={{ width: '100%' }}>
            {ENTITY_STATUS_LABEL[active]}
            {!locked &&
            (isAdmin ||
              (hasDeleteAccess && owner._id === user._id && !bulkEdit)) ? (
              <DeleteIcon
                className={cx('table__delete', {
                  disabled: active,
                  'fill-red': !active,
                  'fill-grey': active,
                })}
                alt={'delete'}
                onClick={() => !active && setItemToDelete(_id)}
              />
            ) : null}
          </div>
        )
      },
      accessorFn: (row) => (row.active ? 1 : 0),
      minSize: 120,
      maxSize: 120,
      sortType: 'boolean',
    },
  ]

  /* Different columns for non-admin */
  if (!isAdmin) {
    // Insert Source column as the 3rd one
    columns.splice(2, 0, {
      id: 'source',
      header: 'Source',
      accessorFn: (row) => (row.isGlobal ? '3Q' : 'Your Organization'),
    })
    // Remove Frequency & Status Columns
    // Add On/Off toggle column
    columns.splice(5, 2, {
      header: 'On/Off',
      id: 'toggle',
      width: 60,
      minWidth: 60,
      cell: (cell) => {
        const {
          alertRunning,
          required,
          _id: alertId,
          active,
          owner,
        } = cell.row.original
        return (
          <div className="alerts__onoff">
            {hasToggleAlertAccess ? (
              <Toggle
                defaultChecked={
                  alertRunning.length && active
                    ? alertRunning.find((el) => el.company === company)?.running
                    : false
                }
                onChange={(checked) => {
                  setLoadingAlerts((loadingAlerts) => ({
                    ...loadingAlerts,
                    [alertId]: true,
                  }))
                  updateStatus(alertId, company, checked)
                    .then(() => fetchData())
                    .finally(() => {
                      setLoadingAlerts((loadingAlerts) => ({
                        ...loadingAlerts,
                        [alertId]: false,
                      }))
                    })
                }}
                disabled={
                  loadingAlerts[alertId] ||
                  ((!active || required) && user._id !== owner._id)
                }
              />
            ) : null}
            {required && <img alt="lock" src={lockIcon} />}
          </div>
        )
      },
      accessorFn: (row) => {
        const { alertRunning } = row
        const isRunning = alertRunning.length
          ? !!alertRunning.find((el) => el.company === company)?.running
          : false
        return isRunning ? 1 : 0
      },
    })
  }

  const _onDelete = () => {
    setLoading(true)
    deleteAlert(dispatch, itemToDelete, isAdmin, company)
      .then(() => {
        // Remove deleted alert from selected list
        const selectedAlertWithoutJustDeleted = selectedAlerts.filter(
          ({ _id }) => _id !== itemToDelete
        )

        setSelectedAlerts(selectedAlertWithoutJustDeleted)

        // If there are no more alerts left, get out of bulk-edit mode
        if (!selectedAlertWithoutJustDeleted.length) {
          setBulkEdit(false)
        }

        fetchData()
      })
      .finally(() => setLoading(false))
    setItemToDelete(null)
  }

  const _alertDeletionModal = () =>
    itemToDelete && (
      <Modal
        opened={!!itemToDelete}
        button={<Button value="Delete" onClick={_onDelete} />}
        buttonSecondary={
          <Button
            value={'Cancel'}
            onClick={() => setItemToDelete(null)}
            secondaryGray
          />
        }
        heading="Are you sure you want to delete this alert?"
        className="alert-categories__modal"
      />
    )

  if (bulkEdit) {
    return (
      <AlertsBulkEdit
        alertCategories={alertCategories}
        alertDeletionModal={_alertDeletionModal}
        allCompanies={allCompanies}
        columns={columns}
        company={company}
        dispatch={dispatch}
        loading={loading}
        isAdmin={isAdmin}
        selectedAlerts={selectedAlerts}
        setBulkEdit={setBulkEdit}
        setSelectedAlerts={setSelectedAlerts}
        teams={teams}
        user={user}
        navigateOnAction={navigateOnAction}
      />
    )
  }

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, filterSort.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  return (
    <>
      {_alertDeletionModal()}

      <div className="alerts">
        <Helmet>
          <title>Alerts</title>
        </Helmet>
        <div className="heading" data-cy="page-heading">
          Alerts
          <div className="heading__buttons">
            {hasCreateAccess && (
              <Button
                value="Create Alert"
                onClick={() => navigate('/alerts/new')}
              />
            )}
            {hasAlertTriggerAccess && (
              <Button
                value={
                  <div className="alerts__heading__buttons__active-shortcut">
                    <span className="alerts__heading__buttons__active-shortcut-icon" />
                    <p className="alerts__heading__buttons__active-shortcut-text">
                      Active Alerts
                    </p>
                  </div>
                }
                onClick={() => navigate('/alert-triggers')}
                secondary
              />
            )}
          </div>
        </div>
        <AlertFilters
          allCompanies={allCompanies}
          loading={loading}
          setLoading={setLoading}
          dispatch={dispatch}
          tableContainer={tableContainer}
          filters={filterSort.filter}
        />
        {loading}
        <Table
          columns={columns}
          data={list}
          showPagination={true}
          initialState={{
            sortBy: filterSort.sort,
            pagination: {
              pageIndex: filterSort.pagination.page,
              pageSize: filterSort.pagination.size,
            },
          }}
          onSortChanged={sortChanged}
          tableContainer={tableContainer}
          onSelect={isAdmin ? setSelectedAlerts : null}
          loading={!list || filterLoading}
          paginationValues={[10, 20, 50, 100]}
          manualPagination={true}
          manualCount={total}
          manualFilter={filterSort?.filter?.keyword}
          pageCount={Math.ceil(total / filterSort.pagination?.size)}
          onChangeTableView={onChangeTableView}
          showSearchInput={true}
          hideRowSelectionFn={(cell) => {
            return !!locks?.[cell?.row?.id]
          }}
          serverSideFetch={true}
          autoResetPageIndex={false}
        />
      </div>
      {selectedAlerts.length ? (
        <StickyFooter
          buttons={[
            {
              value: 'Edit Selected',
              onClick: () => {
                setBulkEdit(true)
              },
            },
          ]}
        />
      ) : null}
    </>
  )
}

const AlertFilters = ({ tableContainer, dispatch, filters, allCompanies }) => {
  const initialFilterState =
    defaultFilterSortConfig.filterSort[tableContainer].filter
  const [state, setState] = useState({ ...initialFilterState })

  const [filterData, setFilterData] = useState(null)

  // Set filters data
  useEffect(() => {
    setState(filters)
  }, [filters])

  useEffect(() => {
    getAlertFilters().then(setFilterData).catch(console.error)
  }, [])

  const applyFilters = () => {
    saveFilter(dispatch, tableContainer, state)
  }

  const clearFilters = () => {
    resetFilterSort(dispatch, tableContainer)
  }

  const [initial, current] = getCommonFields(initialFilterState, state)
  const [currentState] = getCommonFields(state, current)

  return (
    <Filters
      onApply={applyFilters}
      onClear={clearFilters}
      disableClearOnUnmount={true}
      initialApplied={!isEqual(initial, current)}
      initialState={initial}
      currentState={currentState}
      loading={!filterData}
    >
      <FilterSection>
        <Input
          search={true}
          onChange={(keyword) => setState({ ...state, keyword })}
          value={state.keyword}
          placeholder="Enter Alert Name, Category, or Publisher"
        />
      </FilterSection>

      <FilterSection label="Owner">
        <Dropdown
          defaultOptionText="All Creators"
          options={(filterData?.owners || [])
            .map((o) => ({
              label: `${o.firstName} ${o.lastName}`,
              value: o._id,
            }))
            .sort((a, b) => compareIgnoreCase(a.label, b.label))}
          defaultState={state.creator}
          deselectLabel="All Creators"
          onChange={(creator) => setState({ ...state, creator })}
        />
      </FilterSection>

      <FilterSection label="Alert Category">
        <Dropdown
          defaultOptionText="All Alert Categories"
          options={(filterData?.categories || [])
            .map((c) => ({ label: c.name, value: c._id }))
            .sort((a, b) => compareIgnoreCase(a.label, b.label))}
          defaultState={state.category}
          deselectLabel="All Alert Categories"
          onChange={(category) => setState({ ...state, category })}
        />
      </FilterSection>

      <FilterSection label="Alert Types">
        <Dropdown
          defaultOptionText="All Alert Types"
          options={ALERT_TYPES.map((type) => ({
            value: type,
            label: type,
          })).sort((a, b) => compareIgnoreCase(a.label, b.label))}
          defaultState={state.alertType}
          deselectLabel="All Alert Types"
          onChange={(alertType) => setState({ ...state, alertType })}
        />
      </FilterSection>

      <FilterSection label="Publishers">
        <DropdownWithSubsections
          defaultOptionText="All Publishers"
          options={Object.entries(ACCOUNT_TYPE_NAMES)
            .map(([value, label]) => ({
              value,
              label,
            }))
            .sort((a, b) => compareIgnoreCase(a.label, b.label))}
          selectAllOptions={{
            label: 'All Publishers',
            allSelected:
              state.channel.length ===
              Object.entries(ACCOUNT_TYPE_NAMES).map(([value]) => value).length,
            onCheck: () => {
              const allChannels = Object.entries(ACCOUNT_TYPE_NAMES).map(
                ([value]) => value
              )
              const allSelected = state.channel.length === allChannels.length
              !allSelected
                ? setState({
                    ...state,
                    channel: allChannels,
                  })
                : setState({
                    ...state,
                    channel: [],
                  })
            },
          }}
          defaultState={state.channel}
          deselectLabel="All Publishers"
          selectedItems={state.channel}
          onChange={(channel) => {
            setState({
              ...state,
              channel: channel,
            })
          }}
        />
      </FilterSection>

      <FilterSection label="Granularity">
        <Dropdown
          defaultOptionText="Granularity"
          options={Object.values(ALERT_GRANULARITY).map((granularity) => ({
            label: ALERT_GRANULARITY_LABELS[granularity],
            value: granularity,
          }))}
          defaultState={state.granularity}
          selectAll="All"
          multiSelect={true}
          selectedItems={state.granularity}
          onChange={(granularity) => {
            setState({ ...state, granularity: granularity })
          }}
        />
      </FilterSection>

      <div className="align-row wrap twin">
        <FilterSection label="Status">
          <Dropdown
            defaultOptionText="All Statuses"
            options={ENTITY_STATUS_OPTIONS.sort((a, b) =>
              compareIgnoreCase(a.label, b.label)
            )}
            defaultState={state.active}
            deselectLabel="All Statuses"
            onChange={(active) => setState({ ...state, active })}
          />
        </FilterSection>
        <FilterSection label="Time">
          <Dropdown
            options={ALERT_TIME}
            defaultOptionText="All"
            selectAll={'All'}
            multiSelect={true}
            selectedItems={state.time}
            onChange={(time) => setState({ ...state, time })}
          />
        </FilterSection>
      </div>

      <FilterSection label="Client / Business Unit / Global">
        <RadioNoHooks
          label="Alerts applied to selected Client / BU / Global"
          className="blue"
          value={true}
          checked={state.appliedToSelected}
          onChange={() => setState({ ...state, appliedToSelected: true })}
        />

        <RadioNoHooks
          label="Alerts not applied to selected Client / BU"
          value={false}
          className="blue"
          checked={!state.appliedToSelected}
          onChange={() => setState({ ...state, appliedToSelected: false })}
        />

        <DropdownWithSubsections
          multiSelect={true}
          options={allCompanies}
          onChange={(companies) => {
            setState({
              ...state,
              selectedCompanies: companies,
              allCompaniesSelected: false,
            })
          }}
          selectAllOptions={
            state.appliedToSelected && {
              label: 'All Clients (Global)',
              allSelected: state.allCompaniesSelected,
              onCheck: () =>
                setState({
                  ...state,
                  selectedCompanies: [],
                  allCompaniesSelected: !state.allCompaniesSelected,
                }),
            }
          }
          placeholder="View All"
          selectedItems={state.selectedCompanies}
        />
      </FilterSection>
    </Filters>
  )
}

AlertFilters.propTypes = {
  company: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  tableContainer: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  allCompanies: PropTypes.array,
}

AlertsModule.propTypes = {
  updateStatus: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  company: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  tableContainer: PropTypes.string.isRequired,
  filterLoading: PropTypes.bool,
  teams: PropTypes.array,
  alertCategories: PropTypes.array,
  fetchData: PropTypes.func.isRequired,
}

export default AlertsModule
