import React from 'react'
import PropTypes from 'prop-types'

import { useTooltip } from 'components/tooltip'

const Dot = ({
  width = 10,
  height = 10,
  backgroundColor = '#fff',
  className,
  value = '',
  tooltip,
}) => {
  const [showTooltip, hideTooltip, Tooltip] = useTooltip(tooltip)
  const style = {
    backgroundColor,
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: '50%',
  }

  return (
    <div
      data-testid="dot"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      className={`align-column center ${className}`}
      style={style}
    >
      {tooltip && <Tooltip />}
      {value}
    </div>
  )
}

export default Dot

Dot.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
