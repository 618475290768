import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getCalculatedMetricsList } from 'modules/calculated-metrics/actions'

// Components
import EntitySelectorFilters, {
  FILTER_TYPE,
} from 'components/entity-selector/filters'
import EntitySelectorActions from 'components/entity-selector/action-bar'
import LineDivider from 'components/line-divider/index'
import Button from 'components/button/index'

// Icons
import { ReactComponent as ClearIcon } from 'assets/clear_all_red.svg'
import { getAlertFilters } from 'modules/alerts/actions'

// Utils
import { utils, alert, metrics } from '@decision-sciences/qontrol-common'
import {
  TABLE_CONTAINER,
  defaultFilterSortConfig,
} from 'modules/table-filter-sort/constants'
import { CheckboxNoHooks } from 'components/checkbox/index'
import { ALERT_FILTER_ORDER } from 'modules/alerts/constants'
import { ALERT_DETAILS_MAP } from './cell'

const tableContainer = TABLE_CONTAINER.CLIENT_ALERT_MANAGEMENT
const { compareIgnoreCase } = utils.string
const {
  ALERT_TIME,
  ALERT_VALUE_MAP,
  ALERT_GRANULARITY,
  ALERT_GRANULARITY_LABELS,
  UNSET,
  SETTINGS_ALERT_METRIC_FILTER,
} = alert
const { METRICS, SPECIAL_METRICS } = metrics

/**
 * Component to filter alert table
 * @param {Object} props.filter Filter object
 * @param {Function} props.setFilter Function to set filters
 * @param {Function} props.onApplyFilters Function to apply selected filters
 * @param {Function} props.onClearFilters Function to clear selected filters
 * @returns {React.FunctionComponent}
 */
const AlertFilters = ({
  filter,
  setFilter,
  onApplyFilters,
  onClearFilters,
}) => {
  const defaultFilter =
    defaultFilterSortConfig.filterSort[tableContainer].filter

  const [filterData, setFilterData] = useState(null)
  const [displayFilters, setDisplayFilters] = useState(filter)
  const [calculatedMetrics, setCalculatedMetrics] = useState([])

  useEffect(() => {
    getAlertFilters().then(setFilterData).catch(console.error)
    getCalculatedMetricsList().then(({ data }) => setCalculatedMetrics(data))
  }, [])

  const allMetrics = useMemo(() => {
    let mappedCalculatedMetrics = {}
    if (calculatedMetrics.length) {
      mappedCalculatedMetrics = calculatedMetrics.reduce(
        (acc, calculatedMetric) => {
          if (!calculatedMetric) {
            return acc
          }
          const { key, name } = calculatedMetric
          return {
            ...acc,
            [key]: {
              alertsFilterLabel: name,
            },
          }
        },
        {}
      )
    }
    return { ...mappedCalculatedMetrics, ...METRICS, ...SPECIAL_METRICS }
  }, [JSON.stringify(calculatedMetrics)])

  const getMetricLabel = (metric) => {
    const configuredMetric = allMetrics[metric]
    if (!configuredMetric) {
      return metric
    }
    return (
      configuredMetric.alertsFilterLabel || configuredMetric.label || metric
    )
  }

  const filterMetrics = useMemo(() => {
    if (!filterData?.metrics) {
      return []
    }
    const metricOptions = filterData.metrics
      .sort((m1, m2) => {
        const m1Index = ALERT_FILTER_ORDER.indexOf(m1)
        const m2Index = ALERT_FILTER_ORDER.indexOf(m2)
        if (m1Index < 1) {
          return 1
        }
        if (m2Index < 1) {
          return -1
        }
        return m1Index - m2Index
      })
      .map((metric) => ({
        label: getMetricLabel(metric),
        value: metric,
      }))

    return [
      { label: UNSET, value: UNSET },
      {
        label: SETTINGS_ALERT_METRIC_FILTER,
        value: SETTINGS_ALERT_METRIC_FILTER,
      },
      ...metricOptions,
    ]
  }, [JSON.stringify(filterData?.metrics || []), allMetrics])

  const _renderValueFilter = (option, selectedItems) => (
    <CheckboxNoHooks
      label={option.label}
      icon={ALERT_DETAILS_MAP[option.value]?.icon}
      isChecked={selectedItems.indexOf(option.value) > -1}
    />
  )

  const filters = [
    {
      fieldKey: 'keyword',
      label: 'Search',
      placeholder: 'Search',
      type: FILTER_TYPE.SEARCH,
    },
    {
      fields: [
        {
          fieldKey: 'alertValue',
          label: 'Value',
          placeholder: 'All',
          type: FILTER_TYPE.DROPDOWN_MULTISELECT,
          selectAll: 'All',
          options: Object.values(ALERT_VALUE_MAP).map((key) => ({
            label: key,
            value: key,
          })),
          optionRenderer: _renderValueFilter,
        },
        {
          fieldKey: 'isGlobal',
          label: 'Source',
          placeholder: 'All',
          type: FILTER_TYPE.DROPDOWN_MULTISELECT,
          selectAll: 'All',
          options: [
            { label: 'Qontrol', value: true },
            { label: 'Your Organization', value: false },
          ],
        },
      ],
      type: FILTER_TYPE.CLUSTER,
    },
    {
      fieldKey: 'creator',
      label: 'Owner',
      placeholder: 'All',
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      selectAll: 'All',
      options: (filterData?.owners || [])
        .map((o) => ({
          label: `${o.firstName} ${o.lastName}`,
          value: o._id,
        }))
        .sort((a, b) => compareIgnoreCase(a.label, b.label)),
    },
    {
      fields: [
        {
          fieldKey: 'time',
          label: 'Time',
          placeholder: 'All',
          type: FILTER_TYPE.DROPDOWN_MULTISELECT,
          selectAll: 'All',
          options: ALERT_TIME,
        },
        {
          fieldKey: 'granularity',
          label: 'Granularity',
          placeholder: 'All',
          type: FILTER_TYPE.DROPDOWN_MULTISELECT,
          selectAll: 'All',
          options: [
            ...Object.values(ALERT_GRANULARITY).map((granularity) => ({
              label: ALERT_GRANULARITY_LABELS[granularity],
              value: granularity,
            })),
          ],
        },
      ],
      type: FILTER_TYPE.CLUSTER,
    },
    {
      fieldKey: 'category',
      label: 'Alert Category',
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      selectAll: 'All',
      placeholder: 'All',
      options: (filterData?.categories || [])
        .map((c) => ({ label: c.name, value: c._id }))
        .sort((a, b) => compareIgnoreCase(a.label, b.label)),
    },
    {
      fieldKey: 'metric',
      label: 'Metric',
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      selectAll: 'All',
      placeholder: 'All',
      options: filterMetrics,
    },
  ]

  const handleFiltersChange = (fieldKey) => (value) => {
    const valueToSave = Array.isArray(value)
      ? value.filter((item) => item !== 'UNSET')
      : value
    const changes = {
      ...filter,
      [fieldKey]: valueToSave,
    }

    setDisplayFilters({ ...displayFilters, [fieldKey]: value })
    setFilter(changes)
  }

  const clearFilters = () => {
    setFilter({ ...defaultFilter })
    setDisplayFilters({ ...defaultFilter })
    onClearFilters()
  }

  return (
    <div className="form__section__body margin-bottom-20 padding-0 alert-filters">
      <h3 className="generic-heading generic-heading--no-margin padding-15">
        Filters
      </h3>
      <LineDivider />
      <div className="padding-10">
        <EntitySelectorFilters
          filters={filters}
          selectedFilters={displayFilters}
          onFiltersChange={handleFiltersChange}
        />
      </div>
      <LineDivider />
      <EntitySelectorActions
        hasFilters
        onApplyFilters={onApplyFilters}
        actions={
          <Button
            onClick={clearFilters}
            secondaryRed
            compact
            className="clear-filters"
            value={
              <div className="align-row">
                <ClearIcon width={16} height={16} className="fill-red" />
                <div className="margin-left-10">Clear All</div>
              </div>
            }
          />
        }
      />
    </div>
  )
}

export default AlertFilters

AlertFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
}
