import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'globalReports'

/** Initial state of the reducer */
const initialState = {
  list: null,
  viewList: null,
  currentGlobalReport: null,
  favList: [],
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_GLOBAL_REPORTS]: (state, { list }) => {
    return { ...state, list }
  },

  [ACTIONS.SET_VIEW_GLOBAL_REPORTS]: (state, { viewList, favList }) => {
    return { ...state, viewList, favList }
  },

  [ACTIONS.SET_EDITED_GOBAL_REPORT]: (state, { currentGlobalReport }) => {
    return { ...state, currentGlobalReport }
  },

  [ACTIONS.DELETE_GLOBAL_REPORT]: (state, { id }) => {
    if (state.list) {
      const filteredList = [...state.list].filter(({ _id }) => _id !== id)
      return { ...state, list: filteredList }
    }
    return state
  },

  [ACTIONS.VIEW_LIST_ITEM_CHANGED]: (state, { id, changes }) => {
    if (state.viewList) {
      const updatedViewList = [...state.viewList].map((globalReport) => {
        if (globalReport._id === id) {
          return { ...globalReport, ...changes }
        }
        return { ...globalReport }
      })
      return {
        ...state,
        viewList: updatedViewList,
        favList: updatedViewList?.filter(({ favorite }) => !!favorite),
      }
    }
    return state
  },
}

export default { name, initialState, reduce }
