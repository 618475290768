import React, { useEffect } from 'react'

/* Components */
import { Helmet } from 'react-helmet'
import { DashboardIndex } from 'modules/global-reports/dashboard'

/* Hooks */
import { redirect } from 'react-router-dom'

/* Utils & Constants */
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'

const GlobalReportsDashboard = () => {
  const hasReadAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.READ,
  })

  useEffect(() => {
    if (!hasReadAccess) {
      redirect('/unauthorized')
    }
  }, [hasReadAccess])

  return (
    <React.Fragment>
      <Helmet>
        <title>{'Reports'}</title>
      </Helmet>
      <DashboardIndex />
    </React.Fragment>
  )
}

export { GlobalReportsDashboard }
