import React from 'react'
import PropTypes from 'prop-types'

import Dot from 'components/dot'
import Table from 'components/table/beta'
import { StaticTooltip } from 'components/tooltip'

import { alert } from '@decision-sciences/qontrol-common'

import './style.scss'
import ScssConstants from '../../../../styles/shared.module.scss'

const { ALERT_VALUE_MAP } = alert

const AlertValueDotsGroup = ({ dots, row }) => {
  const _renderAlertValuePanel = (alerts) => {
    if (!alerts?.length) {
      return null
    }
    const data = alerts.map((alert) => ({ name: alert }))
    const columns = [{ header: 'Alert', accessorKey: 'name' }]
    return <Table height={430} disableSort columns={columns} data={data} />
  }

  return (
    <div className="display-flex">
      {dots.map((dot, index) => {
        const count =
          row?.original?.alertValues?.[dot.companyId]?.[dot.alertLevel]
            ?.count || 0
        const isDisabled = dot.alertLevel && count === 0
        const className = `alert-value-dot ${dot.className || ''} ${
          isDisabled ? 'alert-value-dot__disabled' : ''
        }`

        return (
          <div key={`${index}_${dot.alertLevel}`}>
            <StaticTooltip
              content={
                _renderAlertValuePanel(
                  row?.original?.alertValues?.[dot.companyId]?.[dot.alertLevel]
                    ?.alerts
                ) || dot.tooltip
              }
              isHoverActivated={!dot.alertLevel}
            >
              <Dot
                backgroundColor={dot.backgroundColor}
                width={20}
                height={20}
                className={className}
                value={dot.alertLevel ? String(count) : ''}
              />
            </StaticTooltip>
          </div>
        )
      })}
    </div>
  )
}

export const headerDots = [
  { backgroundColor: ScssConstants.colorGreenMint, tooltip: 'High Value' },
  {
    backgroundColor: ScssConstants.colorButtonGrey,
    className: 'margin-left-5 margin-right-5',
    tooltip: 'Medium Value',
  },
  { backgroundColor: ScssConstants.colorRedVibrant, tooltip: 'Low Value' },
]

export const cellDots = (companyId) => [
  {
    backgroundColor: ScssConstants.colorGreenMint,
    alertLevel: ALERT_VALUE_MAP.HIGH,
    companyId,
  },
  {
    backgroundColor: ScssConstants.colorButtonGrey,
    className: 'margin-left-5 margin-right-5',
    alertLevel: ALERT_VALUE_MAP.MEDIUM,
    companyId,
  },
  {
    backgroundColor: ScssConstants.colorRedVibrant,
    alertLevel: ALERT_VALUE_MAP.LOW,
    companyId,
  },
]

AlertValueDotsGroup.propTypes = {
  dots: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string.isRequired,
      alertLevel: PropTypes.oneOf(Object.values(ALERT_VALUE_MAP)),
      companyId: PropTypes.string,
      className: PropTypes.string,
      tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ).isRequired,
  row: PropTypes.object,
}

export default AlertValueDotsGroup
