import React, { useEffect, useState, useRef } from 'react'

import Loader from 'components/loader'
import StickyFooter from 'components/sticky-footer'
import useLeaveConfirm from 'components/leave-confirm'


import InputText from 'components/input'
import Toggle from 'components/toggle'

import 'modules/platform-settings/global-settings.scss'
import { platform } from '@decision-sciences/qontrol-common'
import { getPlatformSettings, savePlatformSettings } from '../actions'

const { PLATFORM_SETTINGS_TYPES_MAP, CLEANUP_CONFIG } = platform

/**
 * Daily Entity Cleanup
 */
const DailyEntityCleanup = () => {
  const [cleanupTimers, setCleanupTimers] = useState({})

  // Timers are all disabled by default
  const [disabledTimers, setDisabledTimers] = useState(
    Object.keys(CLEANUP_CONFIG).reduce((prev, current) => {
      return { ...prev, [current]: true }
    }, {})
  )

  const [loading, setLoading] = useState(false)

  const backupTimers = useRef(cleanupTimers)

  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  /**
   * On component mount, fetch all the cleanup settings from global-settings
   */
  useEffect(() => {
    setLoading(true)
    getPlatformSettings(PLATFORM_SETTINGS_TYPES_MAP.CLEANUP_TIMERS)
      .then((result) => {
        setCleanupTimers(result)
        setDisabledTimers(
          Object.entries(result).reduce((prev, [key, value]) => {
            return { ...prev, [key]: !value }
          }, disabledTimers)
        )
        backupTimers.current = result
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <Loader />
  }

  /**
   * Save cleanup timers callback
   */
  const onSave = () => {
    setLoading(true)
    savePlatformSettings(
      PLATFORM_SETTINGS_TYPES_MAP.CLEANUP_TIMERS,
      cleanupTimers
    )
      .then((result) => {
        backupTimers.current = result
        setDirty(false)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  // On cancel, revert to previously saved timers and allow user to leave without confirming.
  const onCancel = () => {
    setDirty(false)
    setCleanupTimers(backupTimers.current)
  }

  const onChangeTimer = (key, value) => {
    if (cleanupTimers[key] !== value) {
      setCleanupTimers((data) => ({ ...data, [key]: value }))
      setDirty(true)
    }
  }

  const onCheckTimer = (key) => {
    const value = disabledTimers[key]
    setDisabledTimers((disabled) => ({ ...disabled, [key]: !value }))

    if (value) {
      onChangeTimer(key, 45)
    } else {
      onChangeTimer(key, null)
    }
  }

  return (
    <section className="daily-entity-cleanup form__section__body">
      <LeaveConfirm />

      <h3 className="generic-heading">Data Cleanup</h3>
      <p className="daily-entity-cleanup__description">
        Select the entities below to begin permanently deleting data that has
        been “soft deleted” after a specified number of days. A value of “0”
        means the data for that entity will be permanently deleted the next time
        the cleanup is run.
      </p>
      <div
        data-cy="data-cleanup"
        className="daily-entity-cleanup__table form__half"
      >
        {Object.entries(CLEANUP_CONFIG).map(([key, settings]) => (
          <div
            className="daily-entity-cleanup__table__row"
            data-cy={`data-cleanup-item-${key.toLowerCase()}`}
            key={key}
          >
            <Toggle
              defaultChecked={!disabledTimers[key]}
              onChange={(value) => onCheckTimer(key, value)}
              rightSideLabel={settings.label}
            />
            {!disabledTimers[key] && (
              <div className="daily-entity-cleanup__table__days">
                <span>Permanently delete data after </span>
                <InputText
                  type="number"
                  value={cleanupTimers[key]}
                  onChange={(value) => {
                    onChangeTimer(key, parseInt(value))
                  }}
                  max={9999}
                  disabled={disabledTimers[key]}
                />
                <span>days</span>
              </div>
            )}
          </div>
        ))}
      </div>

      {isDirty && (
        <StickyFooter
          buttons={[
            {
              value: 'Save Changes',
              onClick: onSave,
              disabled: loading,
            },
            {
              value: 'Cancel',
              onClick: onCancel,
              secondaryGray: true,
            },
          ]}
        />
      )}
    </section>
  )
}

export default DailyEntityCleanup

