import React from 'react'
import PropTypes from 'prop-types'

// Components
import EntitySelectorFilters, {
  FILTER_TYPE,
} from 'components/entity-selector/filters'
import EntitySelectorActions from 'components/entity-selector/action-bar'
import LineDivider from 'components/line-divider/index'
import Button from 'components/button/index'

// Icons
import { ReactComponent as ClearIcon } from 'assets/clear_all_red.svg'

// Utils
import { alert } from '@decision-sciences/qontrol-common'
import { CheckboxNoHooks } from 'components/checkbox/index'
import { ALERT_DETAILS_MAP } from '../alerts/cell'

const { ALERT_VALUE_MAP } = alert

/**
 * Component to filter Alert Thresholds table
 * @param {Object} props.filter Filter object
 * @param {Function} props.setFilter Function to set filters
 * @param {Function} props.onApplyFilters Function to apply selected filters
 * @param {Function} props.onClearFilters Function to clear selected filters
 * @returns {React.FunctionComponent}
 */
const AlertThresholdsFilters = ({
  filter,
  setFilter,
  onApplyFilters,
  onClearFilters,
}) => {
  const _renderValueFilter = (option, selectedItems) => (
    <CheckboxNoHooks
      label={option.label}
      icon={ALERT_DETAILS_MAP[option.value]?.icon}
      isChecked={selectedItems.indexOf(option.value) > -1}
    />
  )

  const filters = [
    {
      fieldKey: 'name',
      label: 'Search',
      placeholder: 'Search',
      type: FILTER_TYPE.SEARCH,
    },
    {
      fieldKey: 'alertValue',
      label: 'Value',
      placeholder: 'All',
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      selectAll: 'All',
      options: Object.values(ALERT_VALUE_MAP).map((key) => ({
        label: key,
        value: key,
      })),
      optionRenderer: _renderValueFilter,
    },
  ]

  const handleFiltersChange = (fieldKey) => (value) => {
    const changes = { ...filter, [fieldKey]: value }
    setFilter(changes)
  }

  const clearFilters = () => {
    setFilter({})
    onClearFilters()
  }

  return (
    <div className="form__section__body margin-bottom-20 padding-0 alert-filters">
      <h3 className="generic-heading generic-heading--no-margin padding-15">
        Filters
      </h3>
      <LineDivider />
      <div className="padding-10">
        <EntitySelectorFilters
          filters={filters}
          selectedFilters={filter}
          onFiltersChange={handleFiltersChange}
        />
      </div>
      <LineDivider />
      <EntitySelectorActions
        hasFilters
        onApplyFilters={onApplyFilters}
        actions={
          <Button
            onClick={clearFilters}
            secondaryRed
            compact
            className="clear-filters"
            value={
              <div className="align-row">
                <ClearIcon width={16} height={16} className="fill-red" />
                <div className="margin-left-10">Clear All</div>
              </div>
            }
          />
        }
      />
    </div>
  )
}

export default AlertThresholdsFilters

AlertThresholdsFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
}
