import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import { reports } from '@decision-sciences/qontrol-common'

import { NOT_FOUND_ROUTE } from 'routes'
import { TableauReport } from 'modules/reportings/tableau'
import { LookerStudioReport } from 'modules/reportings/looker-studio'
import { getCurrentCompanyReportings } from 'components/utils/reports'
import { updateLastViewed } from 'modules/global-reports/actions'
const { REPORT_SOURCE } = reports

const NO_REPORTINGS = 'There are no reports for this client'
const REPORTING_NOT_IN_CLIENT = (id) =>
  `There is no reporting with id ${id} that belongs to this client`

/** Reporting Page */
const ReportingsPage = () => {
  const { reportingId } = useParams()
  const {
    state: {
      companies: { currentCompany, list },
    },
    dispatch,
  } = useStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (reportingId && currentCompany) {
      updateLastViewed(dispatch, currentCompany._id, reportingId)
    }
  }, [reportingId, currentCompany])

  const currentCompanyReportings = getCurrentCompanyReportings(
    currentCompany._id,
    list
  )
  let reporting = currentCompanyReportings?.find((r) => r._id === reportingId)

  // In case of no reporting move away from page
  if (!reporting) {
    reporting = {}
    navigate(NOT_FOUND_ROUTE, true)
  }

  const empty = reportingId === 'empty'

  // if current company has reports and (url link id is from another company or empty)
  if (currentCompanyReportings?.length && (!reporting || empty)) {
    // find the first reporting from the current company and go to that link
    navigate(`/reports/${currentCompanyReportings[0]?._id}`)
  }

  return (
    <section>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      {empty ? (
        <p className="reporting">{NO_REPORTINGS}</p>
      ) : !reporting ? (
        <p className="reporting">{REPORTING_NOT_IN_CLIENT(reportingId)}</p>
      ) : (
        <>
          {reporting.source === REPORT_SOURCE.TABLEAU && (
            <TableauReport reporting={reporting} />
          )}
          {reporting.source === REPORT_SOURCE.LOOKER_STUDIO && (
            <LookerStudioReport reporting={reporting} />
          )}
        </>
      )}
    </section>
  )
}
export default ReportingsPage
