import React, { useEffect, useState } from 'react'
import { useStore } from 'store'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

// hooks
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import { useIntermediaryStates } from 'hooks/intermediary-states'
import { useSocket } from 'components/utils/socket'

// components
import Button from 'components/button'
import { getTooltipList } from 'components/utils/tooltip'
import RoundRectangleButton from 'components/round-rectangle-button/index'
import Table from 'components/table/beta'
import Toggle from 'components/toggle'
import CollapsibleSection from 'components/collapsible-section/index'
// import { CopyControl } from 'modules/companies/section-copy-components'
// import { SectionLinkControl } from 'modules/companies/section-link-components'
import AlertFilters from 'modules/companies/subsections/alerts/alert-filters'
import AlertValueCell from 'modules/companies/subsections/alerts/cell'
import AlertEditSection from 'modules/companies/subsections/alerts/alert-edit-section'
// import { SimulationButton } from 'modules/companies/simulations'

// actions
import {
  initGetAlertsPaginated,
  updateStatus,
  getAlertsFromCache,
} from 'modules/alerts/actions'
import {
  bulkEditAlertValueStatus,
  exportAlertsCsv,
  updateAlertValue,
} from 'modules/companies/actions'
import { showErrorMessage } from 'modules/notifications/actions'

import {
  saveFilter,
  saveSort,
  savePagination,
} from 'modules/table-filter-sort/actions'

import {
  TABLE_CONTAINER,
  defaultFilterSortConfig,
} from 'modules/table-filter-sort/constants'

// icons
import { ReactComponent as LockIcon } from 'assets/icon_lock.svg'
import { ReactComponent as LoaderIcon } from 'assets/icon_ring_loader.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as EyeIcon } from 'assets/icon_eye_blue.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'
import { ReactComponent as IconFilter } from 'assets/filters.svg'
import { ReactComponent as MinusIcon } from 'assets/icon_remove_dash.svg'

/* Utils */
import isEqual from 'lodash.isequal'
import { alert, socket, utils } from '@decision-sciences/qontrol-common'

import { FREQUENCY_PERIOD_VALUES } from 'modules/alerts/alerts-thresholds-statistics/utils'

const { ALERT_TYPES_MAP, ALERT_VALUE_MAP } = alert
const { NOTIFICATIONS } = socket
const { pick } = utils.object

/** Alerts section sub-component */
const AlertsSection = ({
  dispatch,
  company,
  parentClient,
  copyFromCompany,
  editing,
  user,
  isViewMode,
  isAlertThresholdsLinked,
  openModal,
  links,
  onChangeLinks,
  simulations,
}) => {
  const { state } = useStore()
  const { users: list } = state
  const tableContainer = TABLE_CONTAINER.CLIENT_ALERT_MANAGEMENT
  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const { getLoadingFor, setLoadingFor } = useIntermediaryStates()

  const [sectionOpen, setSectionOpen] = useState(true)
  const [alertsToEdit, setAlertsToEdit] = useState([])
  const [alertEditMode, setAlertEditMode] = useState(false)
  const [currentClientAlerts, setCurrentClientAlerts] = useState(null)
  const [total, setTotal] = useState(0)

  const defaultFilter =
    defaultFilterSortConfig.filterSort[tableContainer].filter

  const relevantKeys = Object.keys(defaultFilter)
  const [filter, setFilter] = useState(filterSort.filter)
  const [filtersApplied, setFiltersApplied] = useState(
    !isEqual(defaultFilter, pick(filter, relevantKeys))
  )
  const [filtersOpened, setFiltersOpened] = useState(false)

  const {
    pagination: paginationData,
    sort,
    filter: { keyword: searchKeyword },
  } = filterSort
  const defaultSort = defaultFilterSortConfig.filterSort[tableContainer].sort
  const sortData = sort?.length ? sort : defaultSort

  // const editThresholdsAccess = useAccess({
  //   feature: PERMISSIONS.ALERT_THRESHOLDS,
  //   type: PERMISSION_TYPES.EDIT,
  // })
  // const viewThresholdsAccess = useAccess({
  //   feature: PERMISSIONS.ALERT_THRESHOLDS,
  //   type: PERMISSION_TYPES.READ,
  // })
  const hasToggleAlertAccess = useAccess({
    feature: PERMISSIONS.ASSIGNED_ALERTS,
    type: PERMISSION_TYPES.EDIT,
  })

  const editAlertValueAccess = useAccess({
    feature: PERMISSIONS.ASSIGNED_ALERT_VALUE,
    type: PERMISSION_TYPES.EDIT,
  })

  const isLinked = !!parentClient?.links?.alerts?.[company._id]
  const hasEditUserAccess = hasToggleAlertAccess || editAlertValueAccess

  // const readCopySettingsAccess = useAccess({
  //   feature: PERMISSIONS.COPY_CLIENT_SETTINGS_BUSINESS_UNIT,
  //   type: PERMISSION_TYPES.READ,
  // })

  // const editCopySettingsAccess = useAccess({
  //   feature: PERMISSIONS.COPY_CLIENT_SETTINGS_BUSINESS_UNIT,
  //   type: PERMISSION_TYPES.EDIT,
  // })
  // const isEditCopySettingsMode = user.isSuperAdmin || editCopySettingsAccess

  useEffect(() => {
    if (company && !company.new) {
      getAlerts(filterSort.filter ? filterSort.filter : {})
    }
  }, [
    company._id,
    JSON.stringify(paginationData),
    JSON.stringify(sortData),
    searchKeyword,
    isLinked,
    parentClient?._id,
  ])

  const socket = useSocket({
    room: [`${isLinked ? parentClient._id : company._id}-${user._id}`],
  })

  useEffect(() => {
    if (socket?.connected) {
      socket.on(NOTIFICATIONS.clientAlerts.receive, (data) => {
        if (data.success) {
          getAlertsFromCache(isLinked ? parentClient._id : company._id).then(
            (res) => {
              const { list, total } = res
              setCurrentClientAlerts(list)
              setTotal(total)
              setLoadingFor(SECTIONS.TABLE, false)
            }
          )
        } else {
          showErrorMessage('Error fetching client alerts', dispatch)
          setCurrentClientAlerts([])
          setTotal(0)
          setLoadingFor(SECTIONS.TABLE, false)
        }
      })
    }
    return () => socket?.removeAllListeners(NOTIFICATIONS.clientAlerts.receive)
  }, [socket?.connected, company])

  const getAlerts = (filters) => {
    setLoadingFor(SECTIONS.TABLE, true)
    initGetAlertsPaginated(
      isLinked ? parentClient._id : company._id,
      filters,
      paginationData,
      sortData,
      !!copyFromCompany || isLinked
    )
  }
  /**
   * Initializes the alerts CSV export
   */
  const exportCsv = () => {
    setLoadingFor(SECTIONS.EXPORT, true)
    exportAlertsCsv(company.parentCompany || company._id)
      .catch(() => showErrorMessage('Error exporting Alerts CSV', dispatch))
      .finally(() => setLoadingFor(SECTIONS.EXPORT, false))
  }

  const _updateAlertInLocalState = (alertToUpdate, loading) =>
    setCurrentClientAlerts([
      ...currentClientAlerts.map(({ _id, ...alert }) =>
        _id === alertToUpdate._id
          ? { ...alertToUpdate, alertValue: alert.alertValue, loading }
          : { _id, ...alert }
      ),
    ])

  const handleAlertValueChange = (value, alertId) => {
    setLoadingFor(SECTIONS.TABLE, true)
    updateAlertValue(value, alertId, company._id)
      .then((res) =>
        setCurrentClientAlerts([
          ...currentClientAlerts.map(({ _id, ...alert }) =>
            _id === alertId
              ? { _id, ...alert, alertValue: res.value }
              : { _id, ...alert }
          ),
        ])
      )
      .catch((error) => {
        showErrorMessage(error, dispatch)
      })
      .finally(() => setLoadingFor(SECTIONS.TABLE, false))
  }

  const limitWidth = window.screen.width <= 1440 ? 40 : 100

  const columns = [
    // {
    //   header: '',
    //   id: 'actions',
    //   cell: (cell) => {
    //     const {
    //       row: { original: alert },
    //     } = cell
    //     const isSettings = alert.alertType === ALERT_TYPES_MAP.SETTINGS
    //     const hasEdit =
    //       !isAlertThresholdsLinked && editThresholdsAccess && !copyFromCompany

    //     if (hasEdit) {
    //       return (
    //         <EditIcon
    //           onClick={() => !isSettings && openModal(alert)}
    //           className={cx({
    //             disabled: isSettings,
    //             'cursor--pointer': !isSettings,
    //           })}
    //         />
    //       )
    //     }
    //     if (viewThresholdsAccess) {
    //       return (
    //         <EyeIcon
    //           onClick={() => {
    //             !isSettings && openModal(alert)
    //           }}
    //           className={cx({
    //             'disabled-icon': isSettings,
    //             'cursor--pointer': !isSettings,
    //           })}
    //         />
    //       )
    //     }
    //   },
    //   minSize: 40,
    //   maxSize: 40,
    //   size: 40,
    //   textAlign: 'center',
    //   cellTextAlign: 'center',
    // },
    {
      header: '',
      id: 'actions',
      cell: (cell) => {
        const {
          row: { original: alert },
        } = cell
        const isSettings = alert.alertType === ALERT_TYPES_MAP.SETTINGS
        return (
          <EyeIcon
            onClick={() => {
              !isSettings && openModal(alert)
            }}
            className={cx({
              'disabled-icon': isSettings,
              'cursor--pointer': !isSettings,
            })}
          />
        )
      },
      minSize: 40,
      maxSize: 40,
      size: 40,
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      id: 'name',
      header: 'Name',
      accessorFn: (row) =>
        `${row.name?.substring(0, limitWidth)}${
          row.name?.length >= limitWidth ? '...' : ''
        }`,
      tooltip: (row) => {
        return row.name?.length >= limitWidth
          ? getTooltipList('Name', [row.name])
          : null
      },
      minSize: 180,
      maxSize: 180,
      sortingFn: 'name',
    },
    {
      id: 'mappedValue',
      header: 'Value',
      accessorFn: (row) => row.mappedValue,
      cell: ({ row }) => {
        const { active, alertRunning, alertValue, _id: alertId } = row.original
        const clientId = isLinked ? parentClient._id : company._id

        const companyAlertRunning = alertRunning?.find(
          (el) => el.company === clientId
        )
        const getDefaultAlertValue = () => {
          let alertValue
          if (active && companyAlertRunning) {
            alertValue = ALERT_VALUE_MAP.MEDIUM
          } else {
            alertValue = ALERT_VALUE_MAP.LOW
          }
          setCurrentClientAlerts([
            ...currentClientAlerts.map(({ _id, ...alert }) =>
              _id === alertId
                ? { _id, ...alert, alertValue }
                : { _id, ...alert }
            ),
          ])

          return alertValue
        }

        return (
          <AlertValueCell
            alertId={alertId}
            value={alertValue || getDefaultAlertValue()}
            disabled={!editAlertValueAccess || alertEditMode || copyFromCompany}
            onChange={handleAlertValueChange}
          />
        )
      },
      textAlign: 'left',
    },
    {
      header: 'L7D FREQ',
      id: '7dayTriggers',
      accessorFn: (row) => row['7dayTriggers'],
      cell: (cell) => {
        const alert = cell.row.original

        if (simulations?.simulationNeeded) {
          return <div className="blurry">{alert['7dayTriggers']}</div>
        }

        const simulationResults = company.simulationResults?.[alert._id]
        if (!simulationResults) {
          return alert['7dayTriggers']
        }
        const [older, newer] = simulationResults
        const delta =
          newer[FREQUENCY_PERIOD_VALUES.LAST_7_DAYS] -
          older[FREQUENCY_PERIOD_VALUES.LAST_7_DAYS]

        if (!delta) {
          return older[FREQUENCY_PERIOD_VALUES.LAST_7_DAYS]
        }
        const negativeDelta = delta < 0
        return (
          <div className="display-flex">
            {older[FREQUENCY_PERIOD_VALUES.LAST_7_DAYS]}{' '}
            <div
              className={cx('bold', {
                'alerts__negative-delta': negativeDelta,
                'alerts__positive-delta': !negativeDelta,
              })}
            >
              {negativeDelta ? `(${delta})` : `(+${delta})`}
            </div>
          </div>
        )
      },
    },
    {
      header: 'Source',
      id: 'isGlobal',
      accessorFn: (row) => (row.isGlobal ? '3Q' : 'Your Organization'),
    },
    {
      id: 'ownerFullName',
      header: 'Owner',
      accessorFn: (row) => {
        const { name, firstName, lastName } = row.owner
        return name ? name : `${firstName} ${lastName}`
      },
      sorType: 'alphanumeric',
    },
    {
      header: 'Alert Category',
      id: 'category',
      accessorFn: (row) => row.category?.name || '',
    },
    {
      header: 'Turned off/on by',
      id: 'turnedOnOffByUser',
      size: 170,
      cell: (cell) => {
        const { alertRunning, loading, turnedOnOffBy } = cell.row.original

        if (loading) {
          return <LoaderIcon width={30} height={30} />
        }

        const companyAlertRunning = alertRunning?.find(
          (el) => el.company === company._id
        )

        const turnedOnOffByPerCompany = companyAlertRunning?.turnedOnOffBy

        let { user, time } = turnedOnOffBy || {}

        if (turnedOnOffByPerCompany) {
          const { user: userPerCompany, time: timePerCmpany } =
            turnedOnOffByPerCompany

          if (timePerCmpany > time) {
            const companyUser = userPerCompany?._id
              ? userPerCompany
              : list.list?.find((el) => el._id === userPerCompany)
            user = companyUser
            time = timePerCmpany
          }
        }

        return (
          <div>
            <p className="margin-0">
              {user?.firstName} {user?.lastName}
            </p>
            <p className="margin-0">
              {time ? format(new Date(time), 'MM/dd/yyyy HH:mm aa') : ''}
            </p>
          </div>
        )
      },
      accessorFn: ({ turnedOnOffBy: { user } }) => user,
    },
    {
      header: 'Off/On',
      id: 'active',
      size: 60,
      cell: (cell) => {
        const {
          alertRunning,
          required,
          _id: alertId,
          active,
          loading,
        } = cell.row.original

        if (loading) {
          return <LoaderIcon width={30} height={30} />
        }

        const clientId = isLinked ? parentClient._id : company._id
        const companyAlertRunning = alertRunning?.find(
          (el) => el.company === clientId
        )

        return (
          <div className="alerts__onoff">
            {hasToggleAlertAccess ? (
              <Toggle
                defaultChecked={
                  active &&
                  (companyAlertRunning ? companyAlertRunning.running : true)
                }
                onChange={(checked) => {
                  _updateAlertInLocalState(cell.row.original, true)

                  updateStatus(alertId, company._id, checked)
                    .then((res) => _updateAlertInLocalState(res.data, false))
                    .catch(() =>
                      _updateAlertInLocalState(cell.row.original, false)
                    )
                }}
                disabled={
                  !active ||
                  required ||
                  isViewMode ||
                  isLinked ||
                  alertEditMode ||
                  copyFromCompany
                }
              />
            ) : null}
          </div>
        )
      },
      accessorFn: ({ active }) => (active ? 1 : 0),
    },
    {
      header: 'Lock',
      id: 'required',
      size: 40,
      maxSize: 40,
      accessorFn: ({ required }) => (required ? 1 : 0),
      cell: (cell) => {
        const { required } = cell.row.original
        return required ? <LockIcon /> : <MinusIcon width={20} height={20} />
      },
    },
  ]

  const updateAlertsFilter = (newFilter) =>
    saveFilter(dispatch, tableContainer, {
      ...newFilter,
    })

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, filterSort?.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  const paginationChanged = (newPagination) => {
    if (!isEqual(newPagination, paginationData)) {
      savePagination(dispatch, tableContainer, newPagination)
    }
  }

  const filterChanged = (filter) => {
    const newFilter = {
      ...filterSort?.filter,
      keyword: String(filter || '').trim(),
    }
    if (!isEqual(newFilter, filterSort?.filter)) {
      saveFilter(dispatch, tableContainer, newFilter)
    }
  }

  const onChangeTableView = ({ pageIndex, pageSize, filter, sort }) => {
    filterChanged(String(filter || '').trim())

    if (sort) {
      sortChanged(sort)
    }

    paginationChanged({
      page: pageIndex,
      size: pageSize,
    })
  }

  const onApplyFilters = () => {
    setFiltersApplied(true)
    updateAlertsFilter(filter)
    getAlerts(filter)
  }

  const onClearFilters = () => {
    setFiltersApplied(false)
    updateAlertsFilter({ ...defaultFilter })
    getAlerts({ ...defaultFilter })
  }

  const saveEditValueStatus = (editedProps) => {
    setLoadingFor(SECTIONS.TABLE, true)

    const clientId = isLinked ? parentClient._id : company._id
    const alertIdsToUpdate = alertsToEdit.map(({ tableRowId }) => tableRowId)

    bulkEditAlertValueStatus(editedProps, alertIdsToUpdate, clientId)
      .then((response) => {
        if (response.success) {
          // Update local state based on editedProps
          setCurrentClientAlerts((currentAlerts) =>
            currentAlerts.map((alert) => {
              if (alertIdsToUpdate.includes(alert._id)) {
                const updatedAlert = { ...alert }

                if (typeof editedProps.active === 'boolean') {
                  // Update alertRunning based on editedProps.active
                  updatedAlert.alertRunning = alert.alertRunning.map(
                    (runningAlert) => {
                      if (runningAlert.company === clientId) {
                        return {
                          ...runningAlert,
                          running: editedProps.active,
                          turnedOnOffBy: {
                            user: user._id,
                            time: Date.now(),
                          },
                        }
                      }
                      return runningAlert
                    }
                  )
                }

                if (editedProps.value) {
                  updatedAlert.alertValue = editedProps.value
                }

                return updatedAlert
              }

              return alert
            })
          )
        }
      })
      .catch((error) => {
        showErrorMessage(error, dispatch)
      })
      .finally(() => {
        setAlertsToEdit([])
        setAlertEditMode(false)
        setLoadingFor(SECTIONS.TABLE, false)
      })
  }

  return (
    <CollapsibleSection
      defaultCollapsed={!sectionOpen}
      uncollapsible={!editing}
      className={cx({ 'alerts-table': currentClientAlerts?.length < 5 })}
      header={
        <div className="display-flex align-row gap-8">
          <div>Alerts</div>
          {!isViewMode && (
            <div className="display-flex align-row gap-8">
              {/* <SectionLinkControl
                client={company}
                links={links}
                onChangeLinks={onChangeLinks}
                isSectionOpen={sectionOpen}
              /> */}
            </div>
          )}
          {/* {parentClient && readCopySettingsAccess && (
            <div className="display-flex align-row gap-8">
              <CopyControl
                company={company}
                parentCompany={parentClient}
                onChangeCopyCompany={onChangeCopyCompany}
                disabled={!isEditCopySettingsMode}
              />
            </div>
          )} */}
        </div>
      }
      extras={
        <div className="align-row margin-left-auto">
          {alertsToEdit.length > 0 && (
            <Button
              value={
                <div className="display-flex">
                  <EditIcon />
                  <div className="campaign-exclusions-create-actions__save__label">
                    Edit selected
                  </div>
                </div>
              }
              onClick={() => {
                setAlertEditMode(!alertEditMode)
              }}
              secondary={true}
            />
          )}
          {/* <SimulationButton
            disabled={simulations.simulationsDisabled || company.new}
            loading={simulations.loading}
            onClick={() => simulations.simulate()}
          /> */}
          <RoundRectangleButton
            data-cy="alerts-section-export"
            onClick={exportCsv}
            disabled={getLoadingFor(SECTIONS.EXPORT)}
            className="margin-left-16 round-rectangle-button__white"
            contentRender={() => <DownloadIcon />}
          />
          <RoundRectangleButton
            className={cx('margin-left-16 padding-5', {
              'filter-active': filtersOpened || filtersApplied,
            })}
            onClick={() => setFiltersOpened(!filtersOpened)}
            contentRender={() => (
              <IconFilter
                className={
                  filtersOpened || filtersApplied
                    ? 'fill-white'
                    : 'fill-light-blue'
                }
              />
            )}
          />
        </div>
      }
      onCollapseListener={(collapsed) => setSectionOpen(!collapsed)}
    >
      {filtersOpened ? (
        <AlertFilters
          filter={filter}
          setFilter={setFilter}
          onApplyFilters={onApplyFilters}
          onClearFilters={onClearFilters}
        />
      ) : null}
      {alertEditMode && alertsToEdit.length ? (
        <AlertEditSection
          alerts={alertsToEdit}
          onSave={saveEditValueStatus}
          onCancel={() => {
            setAlertEditMode(false)
            setAlertsToEdit([])
          }}
          canEditValue={editAlertValueAccess}
          isLinked={isLinked}
          hasToggleAlertAccess={hasToggleAlertAccess}
        />
      ) : null}
      <Table
        id="alerts-table"
        columns={columns}
        data={
          currentClientAlerts?.map((alert) => ({
            ...alert,
            disableSelect: alertEditMode,
          })) || []
        }
        showPagination={true}
        onSortChanged={sortChanged}
        initialState={{
          sortBy: filterSort.sort,
          pagination: {
            pageIndex: filterSort.pagination.page,
            pageSize: filterSort.pagination.size,
          },
          selectedRowIds: alertsToEdit.map((r) => r.tableRowId),
        }}
        tableContainer={tableContainer}
        height={450}
        onSelect={
          user.isSuperAdmin || hasEditUserAccess ? setAlertsToEdit : null
        }
        loading={getLoadingFor(SECTIONS.TABLE)}
        paginationValues={[10, 20, 50, 100]}
        manualPagination={true}
        manualCount={total}
        manualFilter={filterSort?.filter?.keyword}
        pageCount={Math.ceil(total / paginationData.size)}
        onChangeTableView={onChangeTableView}
        showSearchInput={true}
        serverSideFetch={true}
        autoResetPageIndex={false}
        keepRowSelection={true}
      />
    </CollapsibleSection>
  )
}

export default AlertsSection

const SECTIONS = {
  TABLE: 'table',
  EXPORT: 'export',
}

AlertsSection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  company: PropTypes.object,
  parentClient: PropTypes.object,
  copyFromCompany: PropTypes.object,
  editing: PropTypes.bool,
  openedSubSection: PropTypes.object.isRequired,
  setOpenedSubSection: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isViewMode: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  isAlertThresholdsLinked: PropTypes.bool,
  links: PropTypes.object,
  onChangeLinks: PropTypes.func.isRequired,
  simulations: PropTypes.object,
}
