import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'

/** Components */
import LineDivider from 'components/line-divider/index'

/** Assets */
import { ReactComponent as StarIcon } from 'assets/icon_star.svg'
import { ReactComponent as ArrowIcon } from 'assets/icon_arrow_blue_right.svg'

/** Style */
import './style.scss'

/**
 * Favorites panel component
 * @param options
 * @param {String} options.title Favorite panel title
 * @param {Array<Object>} options.list Favorites list. Entries can have customRoutes that will be used for navigation instead of baseRoute.
 * @param {String} options.baseRoute Index page route for the entity in the favorite list that do not have customRoute
 * @param {String} [options.emptyMessage] Message to display if the list is empty
 * @returns {Node}
 */
const FavoritesPanel = ({ title, list, baseRoute, emptyMessage }) => {
  return (
    <div className="panel">
      <div className="panel__header">
        <div className="panel__header__title">{title}</div>
        <div className="panel__header__icon">
          <StarIcon />
        </div>
      </div>
      <LineDivider />
      <div className="panel__favorites">
        {list.length ? (
          list.map((el) => {
            return (
              <FavoriteRow
                key={el._id}
                name={el.name}
                id={el._id}
                baseRoute={el.customRoute || baseRoute}
              />
            )
          })
        ) : (
          <FavoriteRow name={emptyMessage} baseRoute={baseRoute} />
        )}
      </div>
    </div>
  )
}

FavoritesPanel.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  baseRoute: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
}

/**
 * Favorite row component used in FavoritePanel
 * @param options
 * @param {String} options.name Name of the favorited entity
 * @param {String} [options.id] Id of the favorited entity
 * @param {String} options.baseRoute Index page route for the entity in the favorite list
 * @returns {Node}
 */
const FavoriteRow = ({ name, id, baseRoute }) => {
  const [hovered, setHovered] = useState(false)
  const navigate = useNavigate()

  return (
    <div
      className={cx('favorite-row', { 'favorite-row--hovered': hovered })}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => navigate(`${baseRoute}${id ? `/${id}` : ''}`)}
    >
      <div className="favorite-row__name">{name}</div>
      <div className="favorite-row__icon">
        <ArrowIcon />
      </div>
    </div>
  )
}

FavoriteRow.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  baseRoute: PropTypes.string.isRequired,
}

export default FavoritesPanel
