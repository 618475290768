import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'

// hooks
import { useStore } from 'store'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

// components
import Button from 'components/button'
import { getTooltipList } from 'components/utils/tooltip'
import { useSocket } from 'components/utils/socket'
import Table from 'components/table/beta'
import Modal from 'components/modal'
import Spacer from 'components/spacer'
import Toggle from 'components/toggle'
// import Loader from 'components/loader'
import { AccountIcon } from 'components/account-icon'

// actions
import { setBulkUsers } from 'modules/users/actions'
import {
  changeInactiveCampaignsFlag,
  // changeInactiveCampaignsForComparisonFlag,
} from 'modules/companies/actions'
import { getAllAlertThresholdsWithActiveAlerts } from 'modules/alert-thresholds/actions'

// module components
import AlertsThresholdModal from 'modules/alerts/alerts-thresholds-statistics/simulation/simulation-modal'
import AssignUserModal from 'modules/companies/assign-user-modal'
// import NamingConventionsSection from 'modules/companies/subsections/naming-conventions-section'
// import OfflineDataSection from 'modules/companies/subsections/offline-data-section'
import BudgetPacingSection from 'modules/companies/subsections/budget-pacing/index'
// import ConversionGroupingSection from 'modules/companies/subsections/conversion-grouping/index'
import BusinessUnitsSection from 'modules/companies/subsections/business-units'
// import CampaignComparisonSection from 'modules/companies/subsections/campaign-comparison'
import CampaignExclusionsSection from 'modules/companies/subsections/campaign-exclusions-section'
import AlertsSection from 'modules/companies/subsections/alerts'
import AlertThresholdSection from 'modules/companies/subsections/alert-thresholds'
import ReportsSection from 'modules/companies/subsections/reports'

// constants
import { ACCOUNT_TYPES } from 'modules/accounts/common'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'

// icons
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as BlueLinkIcon } from 'assets/icon_link_blue.svg'
import blueArrowIcon from 'assets/icon_arrow_blue.svg'
import { utils, entityStatus, socket } from '@decision-sciences/qontrol-common'

// import CalculatedMetricsSection from './subsections/calculated-metrics/index'

import './style.scss'

const { equalIds, isEmpty } = utils.object
const { ENTITY_STATUS_LABEL } = entityStatus
const { NOTIFICATIONS } = socket

const EditCompanySubsection = ({
  history,
  editing,
  loading,
  clientErrors,
  setClientErrors,
  kpis,
  loadingKpis,
}) => {
  const { dispatch, state } = useStore()
  const { list: usersList } = state.users
  const { userData } = state.session
  const { active: alertThresholds } = state.alertThresholds
  const [assignUserModal, setAssignUsersModal] = useState(false)
  const [deleteUsersModal, setDeleteUsersModal] = useState(null)
  const [thresholdModalAlert, setThresholdModalAlert] = useState({})
  const [alertThresholdsLoading, setAlertThresholdsLoading] = useState(false)

  const {
    company,
    setCompany,
    isViewMode,
    setDefaultCompany,
    defaultCompany,
    showInheritClientModal,
    setFlags,
    linkClientSections,
    simulations,
    checkForSingleClientAlerts,
    setDirty,
    usersData,
    businessUnitData,
  } = useOutletContext()

  const socket = useSocket({ room: [company._id], roomNeeded: true })

  const clientUsers = usersData.getClientUsers(company._id)
  /** On mount: Fetch alert thresholds */
  useEffect(() => {
    if (!company.new) {
      setAlertThresholdsLoading(true)
      getAllAlertThresholdsWithActiveAlerts(dispatch, company._id).finally(() =>
        setAlertThresholdsLoading(false)
      )
    }
  }, [company.new, company._id])

  // Listen to changes on Caching Job Progress
  useEffect(() => {
    if (socket?.connected) {
      socket.on(NOTIFICATIONS.cachingJobDone.receive, (data) => {
        setFlags(company._id, {
          ...data,
        })
      })
    }
    return () =>
      socket?.removeAllListeners(NOTIFICATIONS.cachingJobDone.receive)
  }, [socket?.connected])
  // Access-related variables
  const hasAccountAccess = useAccess({
    feature: PERMISSIONS.ACCOUNT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasBUAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasAlertsAccess = useAccess({
    feature: PERMISSIONS.ASSIGNED_ALERTS,
    type: PERMISSION_TYPES.READ,
  })
  const hasAlertThresholdAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLDS,
    type: PERMISSION_TYPES.READ,
  })
  const hasUsersAccess = useAccess([
    {
      feature: PERMISSIONS.USERS_INDEX,
      type: PERMISSION_TYPES.READ,
    },
    {
      feature: PERMISSIONS.ASSIGN_USERS_TEAMS_CLIENT,
      type: PERMISSION_TYPES.READ,
    },
  ])
  const hasBudgetPacingAccess = useAccess({
    feature: PERMISSIONS.BUDGET_PACING,
    type: PERMISSION_TYPES.READ,
  })
  // const hasNamingConventionsEditAccess = useAccess({
  //   feature: PERMISSIONS.NAMING_CONVENTIONS,
  //   type: PERMISSION_TYPES.EDIT,
  // })
  // const hasNamingConventionsReadAccess = useAccess({
  //   feature: PERMISSIONS.NAMING_CONVENTIONS,
  //   type: PERMISSION_TYPES.READ,
  // })
  // const hasOfflineDataReadAccess = useAccess({
  //   feature: PERMISSIONS.OFFLINE_DATA,
  //   type: PERMISSION_TYPES.READ,
  // })
  const hasCampaignExclusionsReadAccess = useAccess({
    feature: PERMISSIONS.CAMPAIGN_EXCLUSIONS,
    type: PERMISSION_TYPES.READ,
  })
  const hasReportViewAccess = useAccess({
    feature: PERMISSIONS.REPORTS,
    type: PERMISSION_TYPES.READ,
  })
  // const hasConversionGroupsReadAccess = useAccess({
  //   feature: PERMISSIONS.CONVERSION_GROUPS,
  //   type: PERMISSION_TYPES.READ,
  // })

  const [openedSubSection, setOpenedSubSection] = useState({
    reporting: true,
    accounts: true,
    businessUnits: true,
    alerts: true,
    thresholds: true,
    users: true,
    reportings: true,
    namingConventions: false,
  })

  const onChangeInactiveCampaignsFlag = async (value) => {
    const newValue = await changeInactiveCampaignsFlag(company._id, value)
    setDefaultCompany({
      ...defaultCompany,
      budgetPacingInactiveCampaigns: newValue,
    })
  }

  // const onChangeInactiveCampaignsForComparionFlag = (value) =>
  //   new Promise(async (resolve) => {
  //     const newValue = await changeInactiveCampaignsForComparisonFlag(
  //       company._id,
  //       value
  //     )
  //     setDefaultCompany({
  //       ...defaultCompany,
  //       campaignComparisonInactiveCampaigns: newValue,
  //     })

  //     resolve()
  //   })

  return (
    <div className="align-column edit-subsections">
      {assignUserModal && (
        <AssignUserModal
          open={assignUserModal}
          users={usersList.filter(
            (u) => !u.isSuperAdmin && !equalIds(company.accountLead, u)
          )}
          hiddenUsers={clientUsers}
          onClose={() => {
            setAssignUsersModal(false)
          }}
          title="Assign Users to Client"
          onSave={(users) => {
            const userChanges = {}
            users.forEach((user) => {
              userChanges[user._id] = true
            })
            usersData.setClientUsers({ [company._id]: userChanges }, true)
            setAssignUsersModal(false)
          }}
        />
      )}
      {!!deleteUsersModal && (
        <Modal
          opened={!!deleteUsersModal}
          button={
            <Button
              value="Ok"
              onClick={() => {
                setDeleteUsersModal(null)
              }}
              onEnterKeyPressed={() => {
                setDeleteUsersModal(null)
              }}
            />
          }
          heading={`Unable to delete ${deleteUsersModal} as it is a default approver on ${company.name} or one of its' business units.`}
          className="alert-categories__modal"
        />
      )}

      {!isEmpty(thresholdModalAlert) && (
        <AlertsThresholdModal
          alert={thresholdModalAlert}
          onCancel={() => {
            setThresholdModalAlert({})
          }}
          onSave={(alertThresholds) => {
            setDirty(true)
            setCompany({ alertThresholds })
            setThresholdModalAlert({})
          }}
          company={company}
          defaultClient={defaultCompany}
        />
      )}
      {/*
      {!company.new && (
        <ReportingSection
          data-cy="create-update-client-reporting"
          company={company}
          setCompany={setCompany}
          openedSubSection={openedSubSection}
          setOpenedSubSection={setOpenedSubSection}
          isViewMode={isViewMode}
        />
      )} */}
      {hasBUAccess && (
        <BusinessUnitsSection
          isViewMode={isViewMode}
          businessUnits={businessUnitData.businessUnitList}
          company={company}
          openedSubSection={openedSubSection}
          setOpenedSubSection={setOpenedSubSection}
          editing={editing}
          showInheritClientModal={showInheritClientModal}
        />
      )}
      {hasAlertsAccess && (
        <AlertsSection
          dispatch={dispatch}
          openedSubSection={openedSubSection}
          setOpenedSubSection={setOpenedSubSection}
          company={company}
          editing={editing}
          user={userData}
          isViewMode={isViewMode}
          openModal={setThresholdModalAlert}
          links={company.links?.alerts || {}}
          onChangeLinks={(alerts) => {
            // if (Object.values(alerts).every((isLinked) => !isLinked)) {
            //   linkClientSections({
            //     alerts,
            //   })
            //   return
            // }
            checkForSingleClientAlerts([company._id]).then((canContinue) => {
              if (canContinue) {
                linkClientSections({
                  alerts,
                })
              }
            })
          }}
          simulations={simulations}
        />
      )}
      {hasAlertThresholdAccess && (
        <AlertThresholdSection
          openedSubSection={openedSubSection.thresholds}
          setOpenedSubSection={(thresholds) =>
            setOpenedSubSection({ ...openedSubSection, thresholds })
          }
          company={company}
          defaultCompany={defaultCompany}
          alertThresholds={alertThresholds}
          loading={alertThresholdsLoading}
          setCompany={(company) => {
            setDirty(true)
            setCompany(company)
          }}
          isViewMode={isViewMode}
          links={company.links?.alertThresholds || {}}
          onChangeLinks={(alertThresholds) =>
            linkClientSections({
              alertThresholds,
            })
          }
          simulations={simulations}
        />
      )}
      {hasAccountAccess && (
        <AccountsSection
          history={history}
          dispatch={dispatch}
          company={company}
          setCompany={setCompany}
          editing={editing}
          openedSubSection={openedSubSection}
          setOpenedSubSection={setOpenedSubSection}
          usersInSelectedCompany={clientUsers}
          isViewMode={isViewMode}
        />
      )}
      {hasUsersAccess && (
        <UsersSection
          setCompany={setCompany}
          company={company}
          editing={editing}
          setOpenedSubSection={setOpenedSubSection}
          openedSubSection={openedSubSection}
          dispatch={dispatch}
          history={history}
          setAssignUsersModal={setAssignUsersModal}
          setDeleteUsersModal={setDeleteUsersModal}
          usersInSelectedCompany={clientUsers}
          isViewMode={isViewMode}
          accountLeadUsers={usersList.filter(
            (user) =>
              !user.isSuperAdmin &&
              (equalIds(user, company.accountLead) ||
                company.businessUnits.some((businessUnit) =>
                  equalIds(businessUnit.accountLead, user)
                ))
          )}
        />
      )}
      {hasReportViewAccess ? (
        loading ? (
          <Loader />
        ) : (
          <ReportsSection
            viewMode={isViewMode}
            reportings={company.reportings}
            setCompany={setCompany}
            company={company}
            users={clientUsers}
          />
        )
      ) : null}

      {/* {hasNamingConventionsReadAccess && (
        <NamingConventionsSection
          client={company}
          onChangeConventions={(conventions) => {
            setCompany({ namingConventions: conventions })
          }}
          isViewMode={!hasNamingConventionsEditAccess || isViewMode}
          links={company.links?.namingConventions || {}}
          onChangeLinks={(namingConventions) =>
            linkClientSections({
              namingConventions,
            })
          }
          setOpenedSubSection={setOpenedSubSection}
          openedSubSection={openedSubSection}
        />
      )} */}

      {/* {!company.new && hasOfflineDataReadAccess && (
        <OfflineDataSection
          clientId={company._id}
          offlineData={company.offlineData}
          isViewMode={!userData.isSuperAdmin || isViewMode}
          errors={clientErrors?.offlineData || {}}
          setErrors={setClientErrors}
        />
      )} */}
      {!company.new && hasCampaignExclusionsReadAccess && (
        <CampaignExclusionsSection
          isViewMode={!userData.isSuperAdmin || isViewMode}
          company={company}
          links={company.links?.campaignExclusions || {}}
          onChangeLinks={(campaignExclusions) =>
            linkClientSections({
              campaignExclusions,
            })
          }
        />
      )}
      {hasBudgetPacingAccess && !company.new && (
        <BudgetPacingSection
          isViewMode={isViewMode}
          currentCompany={company}
          companyId={company._id}
          inactiveCampaignsFlag={company.budgetPacingInactiveCampaigns}
          onChangeInactiveCampaignsFlag={onChangeInactiveCampaignsFlag}
        />
      )}
      {/* {!company.new && hasConversionGroupsReadAccess && (
        <ConversionGroupingSection
          isViewMode={!userData.isSuperAdmin || isViewMode}
          currentCompany={company}
          kpis={kpis}
          loadingKpis={loadingKpis}
          offlineData={company.offlineData}
        />
      )} */}
      {/* {!company.new && <ReportingViewsSection company={company} />} */}
      {/* {!company.new && (
        <CalculatedMetricsSection
          company={company}
          setCompany={setCompany}
          viewMode={isViewMode}
          calculatedMetrics={company.calculatedMetrics || []}
          users={clientUsers}
        />
      )} */}
      {/* {!company.new && (
        <CampaignComparisonSection
          isViewMode={isViewMode}
          company={company}
          inactiveCampaignsFlag={company.campaignComparisonInactiveCampaigns}
          onChangeInactiveCampaignsFlag={
            onChangeInactiveCampaignsForComparionFlag
          }
        />
      )} */}
    </div>
  )
}

/** Accounts Section sub-component */
const AccountsSection = ({
  company,
  setCompany,
  dispatch,
  editing,
  openedSubSection,
  setOpenedSubSection,
  usersInSelectedCompany,
  isViewMode,
}) => {
  const navigate = useNavigate()

  const [accountsToEdit, setAccountsToEdit] = useState([])

  const hasAccountCreateAccess = useAccess({
    feature: PERMISSIONS.ACCOUNT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasAccountEditAccess = useAccess({
    feature: PERMISSIONS.ACCOUNT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })

  const accountsToShow = structuredClone(
    company.accounts.filter((account) => account.company) || []
  )

  const companyAccountSet = new Set(
    accountsToShow.map(({ externalAccountId }) => externalAccountId)
  )

  const businessUnitAccounts = company.businessUnits.reduce(
    (prev, businessUnit) => {
      const businessUnitAccounts = businessUnit.accounts.filter(
        (account) =>
          account.company && !companyAccountSet.has(account.externalAccountId)
      )
      return [
        ...prev,
        ...businessUnitAccounts.map((acc) => ({
          ...acc,
          businessUnitName: businessUnit.name,
        })),
      ]
    },
    []
  )

  accountsToShow.unshift(...businessUnitAccounts)

  const companyHasAccounts = accountsToShow.length

  const accountsTable = useMemo(() => {
    return companyHasAccounts ? (
      _returnAccountsTable({
        company,
        setCompany,
        accounts: accountsToShow,
        users: usersInSelectedCompany,
        setAccountsToEdit,
        companyClientId: company.clientId,
        editAccess: hasAccountEditAccess,
        isViewMode,
      })
    ) : (
      <></>
    )
  }, [JSON.stringify(usersInSelectedCompany), JSON.stringify(company.accounts)])

  return (
    <>
      <Spacer />
      <div className="align-row space-between">
        <div data-cy="accounts-heading" className="heading-secondary">
          Accounts
        </div>
        {accountsToEdit.length > 0 ? (
          <Button
            value="Edit Selected"
            onClick={() => {
              navigate(`/company/${company.clientId || 'new'}/account`, {
                state: {
                  accounts: accountsToEdit,
                  isEdit: true,
                },
              })
            }}
            className="space-right fixed-height align-right"
          />
        ) : null}

        {hasAccountCreateAccess && (
          <Button
            value={
              <div className="action-button">
                <BlueLinkIcon />
                <div>Link Accounts</div>
              </div>
            }
            onClick={() => {
              setAccountsToEdit([])
              navigate(`/company/${company.clientId || 'new'}/account`)
            }}
            secondary
            className={`fixed-height ${
              accountsToEdit.length > 0 ? '' : 'align-right'
            }`}
            disabled={isViewMode}
          />
        )}
        {editing && companyHasAccounts ? (
          <div
            className={`sub-section align-center ${
              openedSubSection['accounts'] ? ' opened' : ''
            }`}
            onClick={() =>
              setOpenedSubSection({
                ...openedSubSection,
                accounts: !openedSubSection['accounts'],
              })
            }
          >
            <img src={blueArrowIcon} alt="open table" />
          </div>
        ) : null}
      </div>
      {openedSubSection['accounts'] && accountsTable}
    </>
  )
}

/** Users section sub-component */
const UsersSection = ({
  dispatch,
  editing,
  openedSubSection,
  setOpenedSubSection,
  setDeleteUsersModal,
  setAssignUsersModal,
  accountLeadUsers = [],
}) => {
  const { company, setCompany, isViewMode, businessUnitData, usersData } =
    useOutletContext()

  const navigate = useNavigate()
  const [usersToEdit, setUsersToEdit] = useState([])
  const hasUserAssignAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_USERS_TEAMS_CLIENT,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.DELETE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasEdit = hasEditAccess || hasDeleteAccess
  const usersInSelectedCompany = usersData.getClientUsers(company._id)

  const companyHasUsers =
    company && usersInSelectedCompany && usersInSelectedCompany.length > 0

  const disabledSelect = accountLeadUsers.map((user) => user._id)
  const usersTable = useMemo(() => {
    let displayedUsers = usersInSelectedCompany.filter(
      (user) => !accountLeadUsers.some(({ _id }) => user._id === _id)
    )

    // Show Account Lead users at the top
    if (accountLeadUsers.length) {
      displayedUsers.unshift(...accountLeadUsers)
    }

    // Disable select for row if needed
    if (disabledSelect.length > 0) {
      displayedUsers = displayedUsers.map((obj) => ({
        ...obj,
        ...(disabledSelect.includes(obj._id) && { disableSelect: true }),
      }))
    }

    return _returnUsersTable(
      displayedUsers,
      setUsersToEdit,
      hasEdit,
      isViewMode,
      accountLeadUsers
    )
  }, [JSON.stringify(usersInSelectedCompany), JSON.stringify(accountLeadUsers)])

  // On clicking 'Remove Selected'
  const handleRemoveUsers = () => {
    const okToDelete = usersToEdit.every((user) => {
      const buIdx = company.businessUnits.findIndex(
        (bu) => `${bu.defaultApprover}` === `${user._id}`
      )
      // Break the cycle in case the user tries to delete a default approver. (Either client or business unit)
      if (`${company.defaultApprover}` === `${user._id}` || buIdx !== -1) {
        setDeleteUsersModal(user.name)
        return false
      }

      // Clear backup approver on deleting the backup approver
      if (`${company.backupApprover}` === `${user._id}`) {
        setCompany({
          backupApprover: null,
        })
      }

      // Clear account lead on deleting the backup approver
      if (`${company.accountLead}` === `${user._id}`) {
        setCompany({
          accountLead: null,
        })
      }

      const businessUnitChanges = {}
      businessUnitData.businessUnitList.forEach((businessUnit, index) => {
        businessUnitChanges[businessUnit._id] = {}

        // Clear backup approver on affected business units
        if (businessUnit.backupApprover?.toString() === user._id.toString()) {
          businessUnitChanges[businessUnit._id].backupApprover = null
        }

        // Clear account lead on affected business units
        if (businessUnit.accountLead?.toString() === user._id.toString()) {
          businessUnitChanges[businessUnit._id].accountLead = null
        }
      })

      businessUnitData.setBusinessUnits(businessUnitChanges)

      return true
    })

    if (okToDelete) {
      const userChanges = {}
      usersToEdit.forEach((user) => {
        userChanges[user._id] = false
      })
      usersData.setClientUsers({ [company._id]: userChanges }, true)
    }
  }

  return (
    <div className="form__section">
      <Spacer />
      <div className="align-row">
        <div data-cy="users-header" className="heading-secondary">
          Users
        </div>
        <div className="align-row align-right">
          {usersToEdit.length > 0 && hasEdit && (
            <>
              <Button
                value="Edit Selected"
                onClick={() => {
                  setBulkUsers(dispatch, usersToEdit)
                  navigate('/users')
                }}
                className="fixed-height"
              />
              {hasDeleteAccess && (
                <Button
                  value="Remove Selected"
                  onClick={handleRemoveUsers}
                  secondaryRed
                  className="fixed-height"
                />
              )}
            </>
          )}
          {hasUserAssignAccess && (
            <Button
              value={
                <div className="action-button">
                  <PlusIcon />
                  Assign Users
                </div>
              }
              onClick={() => {
                setAssignUsersModal(true)
              }}
              secondary
              className={`fixed-height`}
              disabled={isViewMode}
            />
          )}
        </div>

        {editing && companyHasUsers && (
          <div
            className={`sub-section align-center ${
              openedSubSection['users'] ? ' opened' : ''
            }`}
            onClick={() =>
              setOpenedSubSection({
                ...openedSubSection,
                users: !openedSubSection['users'],
              })
            }
          >
            <img src={blueArrowIcon} alt="open table" />
          </div>
        )}
      </div>
      {openedSubSection['users'] && usersTable}
    </div>
  )
}

/** Helper to render Accounts Table */
const _returnAccountsTable = ({
  company,
  setCompany,
  accounts,
  users,
  setAccountsToEdit,
  companyClientId,
  editAccess,
  isViewMode,
}) => {
  const columns = [
    {
      header: ' ',
      cell: (cell) => {
        const { key, externalAccountId } = cell.row.original
        if (!editAccess || isViewMode) {
          return null
        }
        return (
          <div className="table__actions align-center">
            <Link
              className="table__edit "
              to={`/company/${companyClientId || 'new'}/account/${
                key || externalAccountId
              }`}
            />
          </div>
        )
      },
      size: 30,
      maxSize: 50,
    },
    {
      header: 'Account',
      cell: ({ row: { original: row } }) => {
        const accountType =
          ACCOUNT_TYPES.find((account) => account.id === row.type)?.id ||
          row.type
        return (
          <div className="align-row center">
            <div className="table__icons">
              <AccountIcon accountType={accountType} />
            </div>
            {row.name}
          </div>
        )
      },
      accessorFn: (row) => {
        const accountType =
          ACCOUNT_TYPES.find((account) => account.id === row.type)?.id ||
          row.type
        return { publisher: accountType, name: row.name }
      },
      sortingFn: 'publisher',
      sortDescFirst: false,
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 280,
    },
    {
      header: 'ID',
      accessorFn: (row) =>
        row.gcmProfileId
          ? `${row.externalAccountId} (profileId: ${row.gcmProfileId})`
          : row.externalAccountId,
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 160,
    },
    {
      header: 'Business Unit',
      accessorFn: (row) => row.businessUnitName || 'None',
      sortingFn: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 200,
    },
    {
      header: 'Users',
      accessorFn: (row) => {
        const usersInAccount =
          row.pendingUsers ||
          users?.filter((user) =>
            user?.clients.find((client) => {
              return client?.accounts.find((account) => {
                return account === row._id
              })
            })
          )
        return usersInAccount.length || '0'
      },
      tooltip: (row) => {
        const usersInAccount =
          row.pendingUsers ||
          users?.filter((user) =>
            user?.clients.find((client) => {
              return client?.accounts.find((account) => {
                return account === row._id
              })
            })
          )
        return getTooltipList(
          'Users',
          usersInAccount.map((item) => item.name)
        )
      },
      textAlign: 'left',
      size: 40,
      sortingFn: 'alphanumeric',
    },
    {
      header: 'Date Added',
      id: 'addedToCompany',
      accessorFn: (row) =>
        new Date(row.addedToCompany || row.createdAt || Date.now()),
      cell: (cell) =>
        cell.getValue('addedToCompany') &&
        format(new Date(cell.getValue('addedToCompany')), 'MM/dd/yyyy'),
      size: 80,
    },
    {
      header: 'Status',
      id: 'status',
      cell: (cell) => {
        const currentAccount = cell.row.original
        if (!editAccess) {
          return ENTITY_STATUS_LABEL[cell.row.original.active]
        }
        return (
          <Toggle
            defaultChecked={currentAccount.active}
            onChange={(value) => {
              const accountChange = {
                ...currentAccount,
                active: value,
                changed: true,
              }
              const newAccounts = structuredClone(company.accounts)

              const foundAccountIndex = newAccounts.findIndex(
                ({ externalAccountId }) =>
                  currentAccount.externalAccountId === externalAccountId
              )

              if (foundAccountIndex === -1) {
                newAccounts.push(accountChange)
              } else {
                newAccounts[foundAccountIndex] = accountChange
              }

              setCompany({
                accounts: newAccounts,
              })
            }}
            disabled={isViewMode}
          />
        )
      },
      textAlign: 'center',
      accessorFn: (row) => (row.active ? 1 : 0),
      size: 40,
      sortType: 'boolean',
    },
  ]

  return (
    <Table
      id="accounts-table"
      columns={columns}
      data={accounts}
      onSelect={editAccess && !isViewMode ? setAccountsToEdit : null}
      className="custom-height__400"
      initialState={{ sortBy: [{ id: 'addedToCompany', desc: false }] }}
    />
  )
}

_returnAccountsTable.propTypes = {
  company: PropTypes.object.isRequired,
  setCompany: PropTypes.func.isRequired,
  accounts: PropTypes.array,
  users: PropTypes.array,
  setAccountsToEdit: PropTypes.func,
  companyId: PropTypes.string,
  dispatch: PropTypes.func,
  editAccess: PropTypes.bool,
  isViewMode: PropTypes.bool,
}

/** Helper renderer for the Users Table */
const _returnUsersTable = (
  users,
  setUsersToEdit,
  hasEditAccess,
  isViewMode,
  accountLeadUsers
) => {
  const columns = [
    {
      header: ' ',
      cell: (cell) => {
        const { _id } = cell.row.original
        if (!hasEditAccess || isViewMode) {
          return null
        }
        return (
          <div className="table__actions align-center">
            <Link className="table__edit" to={`/users/${_id}`} />
          </div>
        )
      },
      size: 30,
      maxSize: 50,
    },
    {
      header: 'Name',
      accessorFn: (row) => row.name || `${row.firstName} ${row.lastName}`,
      sortingFn: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Email',
      accessorFn: (row) => row.email,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Teams',
      textAlign: 'left',
      cellTextAlign: 'left',
      accessorFn: (row) => {
        if (!row.teams.length) {
          return 'No Teams'
        }
        if (row.teams.length === 1) {
          return row.teams[0].name
        }
        return 'Multiple'
      },
      tooltip: (row) => {
        if (row.teams.length <= 1) {
          return null
        }
        return getTooltipList(
          'Teams',
          row.teams.map((item) => item.name)
        )
      },
    },
    {
      header: 'Last Login',
      id: 'lastLogin',
      accessorFn: (row) =>
        row.lastLogin
          ? format(new Date(row.lastLogin), DEFAULT_DATE_FORMAT)
          : ' - ',
    },
    {
      header: 'Status',
      id: 'status',
      cell: (cell) => {
        const { active } = cell.row.original
        return <span>{ENTITY_STATUS_LABEL[active]}</span>
      },
      accessorFn: (row) => (row.active ? 1 : 0),
    },
  ]

  return (
    <Table
      id="users-table"
      columns={columns}
      data={users}
      initialState={{
        disabledSelect: accountLeadUsers.map((user) => user._id),
      }}
      onSelect={hasEditAccess && !isViewMode ? setUsersToEdit : null}
      className="custom-height__400"
    />
  )
}

AccountsSection.propTypes = {
  company: PropTypes.object.isRequired,
  setCompany: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  editing: PropTypes.bool,
  openedSubSection: PropTypes.object.isRequired,
  setOpenedSubSection: PropTypes.func.isRequired,
  usersInSelectedCompany: PropTypes.array,
  isViewMode: PropTypes.bool,
}

UsersSection.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  openedSubSection: PropTypes.object.isRequired,
  setOpenedSubSection: PropTypes.func.isRequired,
  usersInSelectedCompany: PropTypes.array,
  setCompany: PropTypes.func.isRequired,
  setDeleteUsersModal: PropTypes.func.isRequired,
  setAssignUsersModal: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool,
  accountLeadUsers: PropTypes.array,
}

EditCompanySubsection.propTypes = {
  history: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  company: PropTypes.object,
  setCompany: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isViewMode: PropTypes.bool,
  clientErrors: PropTypes.object,
  setClientErrors: PropTypes.func,
  kpis: PropTypes.array,
  loadingKpis: PropTypes.number,
}

export default EditCompanySubsection
