import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
import googleReducer from 'modules/google/reducers'
import microsoftReducer from 'modules/microsoft-config/reducers'
import userReducer from 'modules/users/reducers'
import teamReducer from 'modules/teams/reducers'
import companiesReducer from 'modules/companies/reducers'
import accountsReducer from 'modules/accounts/reducers'
import sessionReducer from 'modules/session/reducers'
import notificationReducer from 'modules/notifications/reducers'
import calculatedMetricsReducer from 'modules/calculated-metrics/reducers'
import alertTemplatesReducer from 'modules/alert-templates/reducers'
import alertsReducer from 'modules/alerts/reducers'
import flowReducer from 'modules/flow/reducers'
import alertThresholdReducer from 'modules/alert-thresholds/reducers'
import permissionGroupReducer from 'modules/permission-groups/reducers'
import leftMenuReducer from 'components/left-menu/reducers'
import legalReducer from 'modules/legal/reducers'
import traqTemplateReducer from 'modules/traq-templates/reducers'
import notificationGroupReduces from 'modules/notification-groups/reducers'
import facebookReducer from 'modules/facebook/reducers'
import editLockReducer from 'contexts/edit-lock/reducers'
import modalReducer from 'components/modal/reducers'
import globalReportsReducer from 'modules/global-reports/reducers'
import dataStoreReducer from 'modules/data-store/reducers'
import tableFilterSortReducer from 'modules/table-filter-sort/reducers'
import globalReducer from 'modules/global/reducers'
import dataStoreGlobalReducer from 'modules/data-store-global/reducer'
import platformSettingsReducer from 'modules/platform-settings/reducer'
import customColumnsReducer from 'modules/custom-columns/reducers'

/** Import all reducers here */
const reducers = [
  googleReducer,
  microsoftReducer,
  userReducer,
  teamReducer,
  companiesReducer,
  accountsReducer,
  notificationReducer,
  sessionReducer,
  calculatedMetricsReducer,
  alertTemplatesReducer,
  alertsReducer,
  flowReducer,
  alertThresholdReducer,
  permissionGroupReducer,
  leftMenuReducer,
  legalReducer,
  traqTemplateReducer,
  notificationGroupReduces,
  facebookReducer,
  editLockReducer,
  modalReducer,
  globalReportsReducer,
  dataStoreReducer,
  tableFilterSortReducer,
  globalReducer,
  dataStoreGlobalReducer,
  platformSettingsReducer,
  customColumnsReducer,
]

/* Construct initial state from reducers */
const initialState = {}
reducers.forEach(
  (reducer) => (initialState[reducer.name] = reducer.initialState)
)

const store = createContext(initialState)
const { Provider } = store

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    let newState = { ...state }
    reducers.forEach((reducer) => {
      if (reducer.name) {
        newState = {
          ...newState,
          [reducer.name]: reducer.reduce(newState[reducer.name], action),
        }
      } else {
        console.error(
          'Reducer "name" field is required. Error for reducer',
          reducer
        )
      }
    })

    return newState
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

StateProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const useStore = () => useContext(store)

export { store, StateProvider, useStore }
