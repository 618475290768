import clientsIcon from 'assets/clients.svg'
import clientIcon from 'assets/icon_global_public.svg'
import userManagementIcon from 'assets/icon_manage_accounts_users.svg'
import calculatedMetricsIcon from 'assets/icon_calculate_metrics.svg'
import alertsIcon from 'assets/icon_edit_notification_alerts.svg'
import briefsIcon from 'assets/icon_briefs.svg'
import cogWhite from 'assets/icon_cog_white.svg'
import reports from 'assets/reports.svg'
import reportsDisabled from 'assets/reports_gray.svg'
import activeAlerts from 'assets/active-alert-triggers.svg'
import teamsIcon from 'assets/icon_groups_teams_icon.svg'
import alertThresholdsIcon from 'assets/icon_motion_sensor_alert_thresholds.svg'
import notificationGroupsIcon from 'assets/icon_wifi_notification_groups.svg'
import globalSettingsIcon from 'assets/icon_settings_global.svg'
import legalIcon from 'assets/icon_balance_legal.svg'
import variablesIcon from 'assets/icon_data_objects_variables.svg'
import permissionGroupIcon from 'assets/icon_group.svg'
import alertCategoriesIcon from 'assets/icon_alert_categories.svg'
import alertTemplatesIcon from 'assets/icon_problem_alert_templates.svg'

export const STORAGE_MENU_STATE = 'left-menu-state'

export const USER_SETTINGS_TABS = [
  {
    key: 'ACCOUNT_EDIT',
    label: 'Your Account',
    route: '/my-account/',
    icon: clientsIcon,
    storageKey: 'yourAccount',
    section: 'account',
  },
  {
    key: 'ACCOUNT_SECURITY',
    label: 'Account Security',
    route: '/my-account/',
    icon: clientsIcon,
    storageKey: 'yourAccountSecurity',
    section: 'security',
  },
]

export const OPERATIONAL_TABS = [
  {
    key: 'CLIENTS',
    label: 'Clients',
    route: '/client-management',
    icon: clientIcon,
  },
  {
    key: 'TEAMS',
    label: 'Teams',
    route: '/team-management',
    icon: teamsIcon,
  },
  {
    key: 'USERS',
    label: 'User Management',
    route: '/users',
    icon: userManagementIcon,
  },
  {
    key: 'PERMISSION_GROUPS',
    label: 'Permission Groups',
    route: '/permission-groups',
    icon: permissionGroupIcon,
  },
  {
    key: 'CALCULATED_METRICS',
    label: 'Calculated Metrics',
    route: '/calculated-metrics',
    icon: calculatedMetricsIcon,
  },
  {
    key: 'ALERTS',
    label: 'Alerts',
    route: '/alerts',
    icon: alertsIcon,
  },
  {
    key: 'ALERT_CATEGORIES',
    label: '– Alert Categories',
    route: '/alert-categories',
    icon: alertCategoriesIcon,
  },
  {
    key: 'ALERT_THRESHOLDS',
    label: '– Alert Thresholds',
    route: '/alert-thresholds',
    icon: alertThresholdsIcon,
  },
  {
    key: 'ALERT_TEMPLATES',
    label: '– Alert Templates',
    route: '/alert-templates',
    icon: alertTemplatesIcon,
  },
  {
    key: 'VARIABLES',
    label: '– Variables',
    route: '/variables',
    icon: variablesIcon,
  },
  {
    key: 'NOTIFICATION_GROUPS',
    label: '– Notification Groups',
    route: '/notification-groups',
    icon: notificationGroupsIcon,
  },
  {
    key: 'GLOBAL_SETTINGS',
    label: 'Global Settings',
    route: '/global-settings',
    icon: globalSettingsIcon,
  },
  {
    key: 'LEGAL',
    label: 'Legal',
    route: '/legal/cms',
    icon: legalIcon,
  },
]

export const BRIEFS_TABS = (HIDE_AUTOMATED_QA) => [
  // Hide until the content and design for this page are built.
  // {
  //   key: 'OVERVIEW',
  //   label: 'Overview',
  //   route: '/briefs',
  //   icon: clientsIcon,
  // },
  // {
  //   key: 'CAMPAIGN_BRIEFS',
  //   label: 'Qompose',
  //   route: '/briefs',
  //   icon: clientsIcon,
  // },
  // Hide until the content and design for this page are built
  // {
  //   key: 'CAMPAIGNS',
  //   label: 'Campaigns +',
  //   route: '/briefs',
  //   icon: clientsIcon,
  // },
]

export const ACTIVE_ALERTS_OPTIONS = [
  {
    key: 'ACTIVE_ALERTS',
    label: 'Active Alerts',
    route: '/alert-triggers',
    icon: clientsIcon,
  },
  {
    key: 'ARCHIVED_ALERTS',
    label: 'Archived Alerts',
    route: '/alert-triggers/archive',
    icon: clientsIcon,
  },
]

export const ANALYTICS_KEYS = {
  TRAQ_DASHBOARD: 'TRAQ_DASHBOARD',
  DATA_STORE: 'DATA_STORE',
  TABLEAU_DASHBOARD: 'TABLEAU_DASHBOARD',
  ANALYSIS_HUB: 'ANALYSIS_HUB',
}

export const ANALYTICS_OPTIONS = [
  {
    key: ANALYTICS_KEYS.TABLEAU_DASHBOARD,
    label: 'Reports',
    route: '/tableau-dashboard',
    icon: reports,
    collapsible: true,
    order: 1,
  },
]
// TODO [hub analysis]robert remove this when reports analysis implementation is complete
const extractHubAnalysis = (menuTabs, hideHubAnalysis) => {
  return hideHubAnalysis
    ? menuTabs.filter((opt) => opt.key !== ANALYTICS_KEYS.ANALYSIS_HUB)
    : menuTabs
}

export const FIXED_LEFT_MENU_TABS = (
  HIDE_DATA_STORE,
  HIDE_AUTOMATED_QA,
  HIDE_HUB_ANALYSIS
) => [
  {
    key: 'ACTIVE_ALERT_TRIGGERS',
    label: 'Active Alert Triggers',
    icon: activeAlerts,
    iconActive: activeAlerts,
    openMenuOptions: ACTIVE_ALERTS_OPTIONS,
  },
  {
    key: 'ANALYTICS',
    label: 'Analytics Menu',
    icon: reports,
    iconActive: reports,
    iconDisabled: reportsDisabled,
    // [AP-8360]- Hide data store from production
    // TO DO: Remove condition based on env var once data store feature is completed
    openMenuOptions:
      HIDE_DATA_STORE === 'on'
        ? extractHubAnalysis(
            ANALYTICS_OPTIONS.filter(
              (opt) => opt.key !== ANALYTICS_KEYS.DATA_STORE
            ),
            HIDE_HUB_ANALYSIS
          )
        : extractHubAnalysis(ANALYTICS_OPTIONS, HIDE_HUB_ANALYSIS),
  },
  {
    key: 'BRIEFS',
    label: 'Briefs Menu',
    icon: briefsIcon,
    iconActive: briefsIcon,
    openMenuOptions: BRIEFS_TABS(HIDE_AUTOMATED_QA),
  },
  {
    key: 'GENERAL',
    label: 'General Menu',
    icon: cogWhite,
    iconActive: cogWhite,
    openMenuOptions: OPERATIONAL_TABS,
  },
  {
    key: 'USER_SETTINGS',
    label: 'User Settings',
    icon: 'avatar',
    openMenuOptions: USER_SETTINGS_TABS,
  },
]

export const ROUTE_TO_TAB = {
  '/google': 'ALL_GOOGLE_CAMPAIGNS',
  '/microsoft': 'ALL_MICROSOFT_CAMPAIGNS',
  '/client-management': 'CLIENTS',
  '/team-management': 'TEAMS',
  '/users': 'USERS',
  '/security': 'SECURITY',
  '/calculated-metrics': 'CALCULATED_METRICS',
  '/alerts': 'ALERTS',
  '/alert-categories': 'ALERT_CATEGORIES',
  '/alert-templates': 'ALERT_TEMPLATES',
  '/variables': 'VARIABLES',
  '/my-account/account': 'ACCOUNT_EDIT',
  '/my-account/security': 'ACCOUNT_SECURITY',
  '/company': 'CLIENTS',
  '/team': 'TEAMS',
  '/account': 'CLIENTS',
  '/user': 'USERS',
  '/assign-user': 'USERS',
  '/calculated-metric': 'CALCULATED_METRICS',
  '/metrics': 'CALCULATED_METRICS',
  '/reporting': 'CALCULATED_METRICS',
  '/alerts/new': 'ALERTS',
  '/alert-templates/new': 'ALERT_TEMPLATES',
  '/variables/new': 'VARIABLES',
  '/briefs/new': 'CAMPAIGN_BRIEFS',
  '/briefs': 'CAMPAIGN_BRIEFS',
  '/config': 'CLIENTS',
  '/alert-triggers/archive': 'ARCHIVED_ALERTS',
  '/alert-triggers': 'ACTIVE_ALERTS',
  '/alert-thresholds': 'ALERT_THRESHOLDS',
  '/permission-groups': 'PERMISSION_GROUPS',
  '/legal/cms': 'LEGAL',
  '/legal/cms/new': 'LEGAL',
  '/embarq-templates': 'TRAQ',
  '/notification-groups': 'NOTIFICATION_GROUPS',
  '/global-settings': 'GLOBAL_SETTINGS',
  '/global-reports': 'GLOBAL_REPORTS',
  '/tableau-dashboard': ANALYTICS_KEYS.TABLEAU_DASHBOARD,
  '/embarq-dashboard': ANALYTICS_KEYS.TRAQ_DASHBOARD,
}

export const LEFT_MENU_2_ROWS_LENGTH = 30
