import { config } from '@decision-sciences/qontrol-common'

const { LC_TABLE_FILTER_SORT, LC_3Q_COMPANY } = config

export const TABLE_CONTAINER = {
  ALERT_MANAGEMENT: 'ALERT_MANAGEMENT',
  ALERT_TEMPLATE: 'ALERT_TEMPLATE',
  CLIENT_ALERT_MANAGEMENT: 'CLIENT_ALERT_MANAGEMENT',
  TEAM_MANAGEMENT: 'TEAM_MANAGEMENT',
  CALCULATED_METRICS: 'CALCULATED_METRICS',
  PERMISSION_GROUPS: 'PERMISSION_GROUPS',
  METRIC_VARIABLES: 'METRIC_VARIABLES',
  NOTIFICATION_GROUPS: 'NOTIFICATION_GROUPS',
  CLIENT_MANAGEMENT: 'CLIENT_MANAGEMENT',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  DATA_STORE: 'DATA_STORE',
  ANALYSIS_HUB: 'ANALYSIS_HUB',
  REPORTS_INDEX: 'REPORTS',
}

export const defaultFilterSortConfig = {
  filterSort: {
    [TABLE_CONTAINER.ALERT_MANAGEMENT]: {
      filter: {
        keyword: '',
        creator: '',
        category: '',
        alertType: '',
        channel: [],
        active: '',
        time: [],
        granularity: [],
        appliedToSelected: true,
        allCompaniesSelected: false,
        selectedCompanies: [],
        metric: [],
      },
      sort: [{ id: 'updatedAt', desc: true }],
      pagination: {
        page: 0,
        size: 10,
      },
    },
    [TABLE_CONTAINER.ALERT_TEMPLATE]: {
      filter: {
        name: '',
      },
      sort: [{ id: 'updatedAt', desc: true }],
      pagination: {
        page: 0,
        size: 10,
      },
    },
    [TABLE_CONTAINER.CLIENT_ALERT_MANAGEMENT]: {
      filter: {
        keyword: '',
        creator: [],
        category: [],
        alertValue: [],
        isGlobal: [],
        time: [],
        granularity: [],
      },
      sort: [{ id: 'updatedAt', desc: true }],
      pagination: {
        page: 0,
        size: 10,
      },
    },
    [TABLE_CONTAINER.ANALYSIS_HUB]: {
      filter: {
        keyword: '',
        creator: '',
        permission: '',
      },
      sort: [{ id: 'updatedAt', desc: true }],
      pagination: {
        page: 0,
        size: 10,
      },
    },
    [TABLE_CONTAINER.DATA_STORE]: {
      filter: {
        keyword: '',
        active: '',
      },
      sort: [],
      pagination: {
        page: 0,
        size: 10,
      },
    },
    [TABLE_CONTAINER.TEAM_MANAGEMENT]: {
      filter: {
        name: '',
        owner: '',
        clients: [],
      },
      sort: [{ id: 'createdAt', desc: true }],
    },
    [TABLE_CONTAINER.CALCULATED_METRICS]: {
      filter: {
        name: '',
        owner: '',
        active: '',
      },
      sort: [{ id: 'updatedAt', desc: true }],
    },
    [TABLE_CONTAINER.PERMISSION_GROUPS]: {
      filter: {
        name: '',
        active: '',
        permissions: [],
      },
      sort: [{ id: 'createdAt', desc: true }],
    },
    [TABLE_CONTAINER.METRIC_VARIABLES]: {
      sort: [{ id: 'updatedAt', desc: true }],
    },
    [TABLE_CONTAINER.NOTIFICATION_GROUPS]: {
      filter: {
        name: '',
        owner: undefined,
        source: undefined,
        publishers: undefined,
        alerts: undefined,
        alertCategories: undefined,
        companies: undefined,
      },
      sort: [{ id: 'Last Modified', desc: true }],
    },
    [TABLE_CONTAINER.CLIENT_MANAGEMENT]: {
      filter: {
        name: null,
        publishers: [],
        accounts: [],
        countries: [],
        states: [],
        status: [],
        companyIds: null,
        options: null,
      },
      sort: [{ id: 'name', desc: false }],
    },
    [TABLE_CONTAINER.USER_MANAGEMENT]: {
      filter: {
        name: '',
        clients: [],
        teams: [],
        status: null,
      },
      sort: [{ id: 'updatedAt', desc: true }],
    },
    [TABLE_CONTAINER.REPORTS_INDEX]: {
      filter: {
        name: '',
        reportType: [],
      },
      sort: [],
      pagination: {
        page: 0,
        size: 10,
      },
    },
  },
}

export const getLocalStorageTableFilterSort = () => {
  let tableFilterSort = null
  try {
    const companyLCItem = localStorage.getItem(LC_3Q_COMPANY)
    const tableFilterSortLCItem = localStorage.getItem(LC_TABLE_FILTER_SORT)
    if (companyLCItem && tableFilterSortLCItem) {
      const clientId = JSON.parse(companyLCItem)?._id
      const tableFilterSortObject = JSON.parse(tableFilterSortLCItem)
      if (clientId && tableFilterSortObject) {
        tableFilterSort = tableFilterSortObject[clientId]
      }
    }
  } catch (e) {
    console.error(e)
  }
  return tableFilterSort
}
