import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import LoadingOverlay from 'components/loading-overlay/index'

import './style.scss'

const attributesToRemove = ['width', 'height', 'src']

/**
 * Renderer for embedded Looker Studio reports
 * @param {Object} props Component props
 * @param {Object} props.reporting Embedded report configuration
 * @returns {React.FunctionComponent}
 */
const LookerStudioReport = ({ reporting }) => {
  const [loading, setLoading] = useState(false)

  const { src, ...attributes } = useMemo(() => {
    const parser = new DOMParser()
    const parsedIframe = parser.parseFromString(
      reporting.embedCode,
      'text/html'
    )
    const iframeNode = parsedIframe.getElementsByTagName('iframe')?.[0]
    if (!iframeNode) {
      return {}
    }
    const src = iframeNode.src
    const attributes = {}
    if (iframeNode.attributes.length) {
      ;[...iframeNode.attributes].forEach((attribute) => {
        if (!attributesToRemove.includes(attribute.name)) {
          attributes[attribute.name] = attribute.value
        }
      })
    }
    return { src, width: '100%', height: '100%', ...iframeNode.attributes }
  }, [reporting.embedCode])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
    }
  }, [src])

  const onLoad = (loading) => () => {
    setLoading(loading)
  }

  return (
    <div className="looker-report">
      <LoadingOverlay visible={loading} />
      <iframe {...attributes} src={src} onLoad={onLoad(false)} />
    </div>
  )
}

LookerStudioReport.propTypes = {
  reporting: PropTypes.object.isRequired,
}

export { LookerStudioReport }
