import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'

import useSession from 'modules/session'

import { getGranularitiesOfAlert } from 'modules/traq-templates/actions'
import { fetchAlerts } from 'modules/alerts/actions'

import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import Table from 'components/table/beta'
import Button from 'components/button'
import { CheckboxNoHooks } from 'components/checkbox'
import Loader from 'components/loader'

import { getTooltipList } from 'components/utils/tooltip'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'

import { notifications } from '@decision-sciences/qontrol-common'

const { FREQUENCY_OPTIONS } = notifications

/**
 * Traq Alert selection Modal
 * @param {Object} props props
 * @param {Object} props.state state of notification group
 * @param {Object} props.setState function to set state
 * @param {Object} props.onCloseX props close modal function
 * @returns {React.Component}
 */
const SelectAlertModalContent = ({
  state,
  setState,
  onCloseX,
  recurrenceFrequency,
  setRecurrenceFrequency,
}) => {
  const [searchName, setSearchName] = useState('')
  const [searchCategory, setSearchCategory] = useState([])
  const [recurrenceAmount, setRecurrenceAmount] = useState('')
  const [loading, setLoading] = useState(true)

  const [selectedAlerts, setSelectedAlerts] = useState([])

  /** Granularities to show on table and in tooltip */
  const [granularities, setGranularities] = useState([])
  const [allAlerts, setAllAlerts] = useState(null)

  const {
    state: {
      alerts: { categories: alertCategories },
      companies: {
        currentCompany: { _id: currentCompanyId },
      },
      notificationGroups,
    },
  } = useStore()
  const allNotificationGroups = notificationGroups?.list

  /** Alerts to display after filtering */
  const [displayedAlerts, setDisplayedAlerts] = useState([])
  const [availableAlerts, setAvailableAlerts] = useState([])

  const [, user] = useSession()
  const isSuperAdmin = user?.isSuperAdmin

  /** Calculate available alerts from all alerts */
  const getAvailableAlerts = () => {
    /**
     * Filter out alerts that are already selected on other notification groups
     * Limited admins can only get alerts they own
     */
    const alertsToConsider =
      allAlerts?.filter(
        ({ _id, notificationGroup, owner }) =>
          /** Check if the notificationGroups on alerts have any data */
          !notificationGroup?.notificationGroup &&
          /** As a failsafe, also check the other way around: if there is any notificationgroup that contains the alert */
          !allNotificationGroups.some((notifGr) =>
            notifGr.alerts.some((al) => al.alert?._id === _id)
          ) &&
          ((!isSuperAdmin && owner?._id === user?._id) || isSuperAdmin)
      ) || []

    /** Filter out alerts on this group */
    const alertsToChooseFrom =
      alertsToConsider?.filter(
        ({ _id }) => !state?.alerts?.some((alert) => _id === alert?.alert?._id)
      ) || []

    const newGranularities =
      alertsToChooseFrom?.reduce((acc, row) => {
        return { ...acc, [row._id]: [...getGranularitiesOfAlert(row)] }
      }, {}) || {}

    setGranularities(newGranularities)

    let newAvailableAlerts

    const alertsAlreadyChosen = [
      ...selectedAlerts,
      ...state?.alerts?.map(({ alert }) => alert),
    ]

    /** 1. Limit selecting alerts with different frequencies */
    /**
     * If we don't have any alert selected yet, do nothing regarding the frequency
     */
    if (!alertsAlreadyChosen?.length) {
      newAvailableAlerts = [...alertsToChooseFrom]
    } else {
      const { frequency: chosenFrequency, amount: chosenAmount } =
        alertsAlreadyChosen[0].recurrence

      newAvailableAlerts = alertsToChooseFrom.filter((alert) => {
        const { recurrence } = alert
        if (!recurrence) {
          return false
        }

        const { frequency, amount } = recurrence

        return (
          frequency.toString() === chosenFrequency.toString() &&
          amount.toString() === chosenAmount.toString()
        )
      })
    }

    setAvailableAlerts(newAvailableAlerts.filter((alert) => !!alert.recurrence))
    /** Displayed alerts get updated after the available alerts did */
  }

  /**
   * When all the alerts update, recalculate the available selectable alerts.
   * We don't need to stop the loading at the end of this useEffect, because updating the available alerts would trigger other side-effects, that would eventually stop the loading.
   */
  useEffect(() => {
    setLoading(true)

    if (!allAlerts) {
      fetchAlerts(
        user.isSuperAdmin ? null : currentCompanyId,
        {},
        state.groupType
      )
        .then(setAllAlerts)
        .catch(console.error)
        .finally(() => setLoading(false))
    } else {
      getAvailableAlerts()
      setLoading(false)
    }
  }, [JSON.stringify(allAlerts), JSON.stringify(state?.alerts)])

  const addAlertToTNotificationGroupAndCloseModal = () => {
    setSelectedAlerts([])
    setState([
      ...(state?.alerts || []),
      ...selectedAlerts?.map((a, index) => ({
        alert: { ...a },
        order: (state?.alerts?.length || 0) + index + 1,
      })),
    ])
    onCloseX()
  }

  const toggleSelectAlert = (e) => {
    const indexOf = selectedAlerts?.findIndex((alert) => alert?._id === e)
    const foundAlert = availableAlerts.find((alert) => alert?._id === e)
    if (indexOf === -1) {
      setSelectedAlerts([...selectedAlerts, foundAlert])
    } else {
      const newSelectedAlerts = [...selectedAlerts]
      newSelectedAlerts.splice(indexOf, 1)
      setSelectedAlerts([...newSelectedAlerts])
    }
  }

  const [allAlertsSelected, setAllAlertsSelected] = useState(false)
  const [disableDropdown, setDisableDropdown] = useState(false)

  /** Control the "Select all" checkbox by manually selecting/deselecting all options */
  useEffect(() => {
    if (
      displayedAlerts.some(
        (dispAlert) =>
          !selectedAlerts.some((selAlert) => selAlert?._id === dispAlert?._id)
      )
    ) {
      setAllAlertsSelected(false)
    } else {
      setAllAlertsSelected(true)
    }
  }, [JSON.stringify(displayedAlerts), JSON.stringify(selectedAlerts)])

  /** Update available alerts when selecting/deselecting, so that only alerts with the same recurrence are shown */
  useEffect(() => {
    if (allAlerts?.length) {
      getAvailableAlerts()
    }
  }, [JSON.stringify(selectedAlerts)])

  /**
   * Update the "Frequency" based on existing alerts and/or selected alerts.
   * If the notification group already has alerts, set the frequency based on these.
   * If there are no already existing alerts, but there are selected alerts, set the frequency based on the selected.
   * If there are no already existing alerts and none selected, reset frequency
   */
  useEffect(() => {
    if (state.alerts?.length) {
      setRecurrenceFrequency(state.alerts[0].alert.recurrence.frequency)
      setRecurrenceAmount(state.alerts[0].alert.recurrence.amount)
      setDisableDropdown(true)
    } else if (selectedAlerts.length) {
      setRecurrenceFrequency(selectedAlerts[0].recurrence.frequency)
      setRecurrenceAmount(selectedAlerts[0].recurrence.amount)
      setDisableDropdown(true)
    } else {
      setRecurrenceFrequency()
      setRecurrenceAmount()
      setDisableDropdown(false)
    }
  }, [JSON.stringify(state.alerts), JSON.stringify(selectedAlerts)])

  /**
   * Check whether there are alerts with multiple frequencies
   * @param {Array<Object>} list list of alerts
   * @returns {Boolean}
   */
  const thereAreAlertsWithDifferentFrequencies = (list) => {
    let toReturn = false
    const frequenciesFoundSoFar = {}

    for (const item of list) {
      /** Map frequencies */
      const { recurrence } = item

      const { frequency, amount } = recurrence

      if (!frequenciesFoundSoFar[frequency]) {
        frequenciesFoundSoFar[frequency] = []
      }
      if (!frequenciesFoundSoFar[frequency].includes(amount.toString())) {
        frequenciesFoundSoFar[frequency].push(amount.toString())
      }

      /** If we already found more than one frequency, stop here */
      if (Object.keys(frequenciesFoundSoFar)?.length) {
        /** If there are more types of frequenciy units */
        if (Object.keys(frequenciesFoundSoFar)?.length > 1) {
          toReturn = true
          break
        }

        /** If there are more amounts within the one frequency unit found so far */
        const frequencyUnitFoundSoFar = Object.keys(frequenciesFoundSoFar)[0]
        if (frequenciesFoundSoFar[frequencyUnitFoundSoFar]?.length > 1) {
          toReturn = true
          break
        }
      }
    }

    return toReturn
  }

  const limitWidth = window.screen.width <= 1440 ? 40 : 100
  const columns = [
    {
      header: () => {
        /** If there are no displayed alert, don't show the select-all checkbox */
        if (!displayedAlerts?.length) {
          return null
        }

        /** If there are alerts with different frequencies, don't show the select-all checkbox */
        if (thereAreAlertsWithDifferentFrequencies(displayedAlerts)) {
          return null
        }

        return (
          <CheckboxNoHooks
            isChecked={allAlertsSelected}
            onChange={(checked) => {
              setAllAlertsSelected(checked)

              if (!allAlertsSelected) {
                setSelectedAlerts([...displayedAlerts])
              } else {
                setSelectedAlerts([])
              }
            }}
            label=""
            className="embarq-template__modal__checkbox-select-all"
          />
        )
      },
      id: 'actions',
      cell: (cell) => (
        <CheckboxNoHooks
          isChecked={selectedAlerts?.some(
            (alert) => alert?._id === cell.row.original?._id
          )}
          onChange={(checked) => {
            toggleSelectAlert(cell.row.original?._id)
          }}
          label=""
        />
      ),
      size: '40',
      minSize: '30',
      maxSize: '30',
    },
    {
      header: 'Alert Name',
      accessorFn: (row) =>
        `${row?.name?.substring(0, limitWidth)}${
          row?.name?.length >= limitWidth ? '...' : ''
        }`,
      tooltip: (row) => {
        return row?.name?.length >= limitWidth
          ? getTooltipList('Alert Name', [row?.name])
          : null
      },
      size: 250,
    },
    {
      header: 'Alert Category',
      accessorFn: (row) => row?.category.name,
    },
    {
      header: 'Frequency',
      accessorFn: (row) => {
        const time = row?.recurrence
        return time ? `Every ${time.amount} ${time.frequency}` : ''
      },
      size: 100,
    },
    {
      header: 'Granularity',
      accessorFn: (row) => {
        if (granularities?.[row?._id]?.length > 1) {
          return 'Multiple'
        } else {
          return granularities?.[row?._id]?.[0]
        }
      },
      tooltip: (row) => {
        return granularities?.[row?._id]?.length > 1
          ? granularities?.[row?._id].join(', \n')
          : null
      },
    },
  ]

  const filterAlerts = ({
    name = null,
    category = null,
    amount = null,
    frequency = null,
  } = {}) => {
    setLoading(true)

    const filterName = name || searchName
    const filterCategory = category || searchCategory
    const filterRecurrenceAmount = amount || recurrenceAmount
    const filterRecurrenceFrequency = frequency || recurrenceFrequency

    const newAlertsToDisplay = availableAlerts.filter(
      ({ name, category, recurrence: thisRecurrence }) => {
        let selectIf = true

        if (
          filterName?.length &&
          !name.match(new RegExp(`.*${filterName}.*`, 'gi'))?.length
        ) {
          selectIf = false
        }

        if (
          selectIf &&
          filterCategory?.length &&
          !filterCategory.some((searchCat) => searchCat === category._id)
        ) {
          selectIf = false
        }

        if (selectIf && filterRecurrenceFrequency) {
          selectIf =
            thisRecurrence?.frequency.toString() ===
            filterRecurrenceFrequency.toString()
        }

        if (selectIf && filterRecurrenceAmount?.length) {
          selectIf =
            thisRecurrence?.amount.toString() ===
            filterRecurrenceAmount.toString()
        }

        return selectIf
      }
    )

    setDisplayedAlerts(newAlertsToDisplay)
    setLoading(false)
  }

  /** Update the displayed alerts based on filtering and available alerts */
  useEffect(() => {
    filterAlerts()
  }, [JSON.stringify(availableAlerts)])

  useEffectOnUpdate(() => {
    filterAlerts({
      category: searchCategory,
      name: searchName,
      amount: recurrenceAmount,
      frequency: recurrenceFrequency,
    })
  }, [searchCategory, searchName, recurrenceAmount, recurrenceFrequency])

  return (
    <>
      <div className="flow-modal-content embarq-template__modal">
        <div
          className="align-row align-center"
          style={{ marginBottom: '20px', justifyContent: 'space-between' }}
        >
          <Input
            list={[]}
            onChange={setSearchName}
            placeholder="Search"
            value={searchName}
            searchBlue
            label="Search"
            className="form__half"
            disabled={loading}
          />
          <Dropdown
            label="Alert Category"
            defaultOptionText="Select Alert Category"
            options={
              alertCategories?.map((category) => ({
                label: category.name,
                value: category._id,
              })) || []
            }
            selectedItems={searchCategory}
            multiSelect
            onChange={setSearchCategory}
            className="form__half"
            disabled={loading}
          />
        </div>

        <div
          className="align-row form__half"
          style={{
            marginBottom: '20px',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Input
            placeholder="Enter Frequency"
            label="Frequency"
            value={recurrenceAmount}
            onChange={setRecurrenceAmount}
            className="form__half"
            type="number"
            disabled={loading || disableDropdown}
          />
          <Dropdown
            defaultOptionText="Select Frequency Unit"
            options={[{ value: null, label: 'All' }, ...FREQUENCY_OPTIONS]}
            defaultState={recurrenceFrequency}
            onChange={setRecurrenceFrequency}
            className="form__half"
            disabled={loading || disableDropdown}
          />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <Table
            className="table--400"
            columns={columns}
            data={displayedAlerts || []}
            loading={loading}
          />
        )}
      </div>
      <div className="modal__buttons">
        <Button
          onClick={addAlertToTNotificationGroupAndCloseModal}
          value="Add Selected Alerts"
        />
        <Button onClick={onCloseX} value="Cancel" secondaryGray />
      </div>
    </>
  )
}

SelectAlertModalContent.propTypes = {
  onCloseX: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  recurrenceFrequency: PropTypes.string,
  setRecurrenceFrequency: PropTypes.func,
}

export default SelectAlertModalContent
