import cx from 'classnames'
import { metrics } from '@decision-sciences/qontrol-common'
const { METRICS, SPECIAL_METRICS } = metrics

export const FREQUENCY_PERIOD_VALUES = {
  LAST_1_DAY: '_1',
  LAST_7_DAYS: '_7',
  LAST_28_DAYS: '_28',
  LAST_84_DAYS: '_84',
  LAST_364_DAYS: '_364',
}
const FREQUENCY_PERIODS_WITH_LABELS = {
  [FREQUENCY_PERIOD_VALUES.LAST_1_DAY]: 'Last 24 Hours',
  [FREQUENCY_PERIOD_VALUES.LAST_7_DAYS]: 'Last 7 Days',
  [FREQUENCY_PERIOD_VALUES.LAST_28_DAYS]: 'Last 28 Days',
  [FREQUENCY_PERIOD_VALUES.LAST_84_DAYS]: 'Last 84 Days',
  [FREQUENCY_PERIOD_VALUES.LAST_364_DAYS]: 'Last 364 Days',
}

export const MOCK_FREQUENCY_DATA = [
  {
    period: FREQUENCY_PERIOD_VALUES.LAST_7_DAYS,
    current: null,
    associated: null,
  },
  {
    period: FREQUENCY_PERIOD_VALUES.LAST_28_DAYS,
    current: null,
    associated: null,
  },
  {
    period: FREQUENCY_PERIOD_VALUES.LAST_84_DAYS,
    current: null,
    associated: null,
  },
  {
    period: FREQUENCY_PERIOD_VALUES.LAST_364_DAYS,
    current: null,
    associated: null,
  },
]

const _triggerColumn = (data) => {
  {
    if (!data) {
      return '--'
    }

    const { original, sign, diff, positive, negative } = data

    if (!diff && diff !== 0) {
      return original
    }

    return (
      <>
        {original}
        <span
          className={cx('frequency-table-diff bold', {
            'frequency-table-diff--positive': positive,
            'frequency-table-diff--negative': negative,
            negative,
          })}
        >
          ({sign}
          {diff})
        </span>
      </>
    )
  }
}

export const FREQUENCY_COLUMNS = [
  {
    header: 'Period',
    cell: (cell) => FREQUENCY_PERIODS_WITH_LABELS[cell.row.original.period],
    textAlign: 'left',
    cellTextAlign: 'left',
    size: 102,
  },
  {
    header: 'This Alert',
    accessorKey: 'current',
    textAlign: 'center',
    cellTextAlign: 'center',
    size: 84,
    cell: (cell) => _triggerColumn(cell.row.original.current),
  },
  {
    header: 'All Assoc. Alerts',
    accessorKey: 'associated',
    textAlign: 'center',
    cellTextAlign: 'center',
    size: 142,
    cell: (cell) => _triggerColumn(cell.row.original.associated),
  },
]

export const getDailyPerformanceReferenceColumns = (metric) => {
  return [
    {
      header: 'Period',
      cell: (cell) => FREQUENCY_PERIODS_WITH_LABELS[cell.row.original.period],
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Min',
      accessorKey: 'min',
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: 'Bottom 25%',
      accessorKey: 'bottom25Percentile',
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: 'Median',
      accessorKey: 'median',
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: 'Mean',
      accessorKey: 'mean',
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: 'Top 25%',
      accessorKey: 'top25Percentile',
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: 'Max',
      accessorKey: 'max',
      textAlign: 'center',
      cellTextAlign: 'center',
    },
  ]
}

export const DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS = {
  [METRICS.Cost.key]: 'Spend',
  [METRICS.Clicks.key]: 'Clicks',
  [METRICS.Impressions.key]: 'Impressions',
}

export const DAILY_PERFORMANCE_REFERENCE_KPI_LABELS = {
  [SPECIAL_METRICS.Primary_KPI.key]: 'Primary KPI Conversions',
  [SPECIAL_METRICS.Secondary_KPI.key]: 'Secondary KPI Conversions',
  [SPECIAL_METRICS.Primary_KPI_Revenue.key]: 'Primary KPI Revenue',
  [SPECIAL_METRICS.Secondary_KPI_Revenue.key]: 'Secondary KPI Revenue',
}

export const FREQUENCY_TOOLTIP_MESSAGE =
  'Estimates below are based on previous run daily simulations'

export const REAL_TRIGGER_FREQUENCY_TOOLTIP =
  'Number of times this alert, as well as other alerts associated with these alert thresholds, have run over various time frames'

export const DAILY_REFERENCE_TABLE_TOOLTIP = (
  <div>
    <span>
      Actual performance across all active accounts in this Client/BusinessUnit.
    </span>
    <br />
    <span>To be used as a reference for setting Alert Thresholds.</span>
  </div>
)

export const mapSimulationResults = (results) => {
  const _getForPeriod = (period) => {
    const currentOriginal =
      results.nrCurrentAlertsTriggeredWithOriginalThresholdValues[period]
    const assocOriginal =
      results.nrAllAlertsTriggeredWithOriginalThresholdValues[period]
    const currentModified =
      results.nrCurrentAlertsTriggeredWithModifiedThresholdValues?.[period]
    const assocModified =
      results.nrAllAlertsTriggeredWithModifiedThresholdValues?.[period]
    const currDiff =
      currentModified !== undefined ? currentModified - currentOriginal : null
    const currentSign = currDiff >= 0 ? '+' : '' // No - for negative, since the number would already contain it

    const assocDiff =
      assocModified !== undefined ? assocModified - assocOriginal : null
    const assocSign = assocDiff >= 0 ? '+' : '' // No - for negative, since the number would already contain it

    const _getPozNeg = (number) => {
      if (!number) {
        return { positive: false, negative: false }
      }

      if (number < 0) {
        return { positive: false, negative: true }
      }

      return { positive: true, negative: false }
    }
    const currentPozNeg = _getPozNeg(currDiff)
    const assocPozNeg = _getPozNeg(assocDiff)

    const current = {
      original: currentOriginal,
      sign: currentSign,
      diff: currDiff,
      ...currentPozNeg,
    }
    const associated = {
      original: assocOriginal,
      sign: assocSign,
      diff: assocDiff,
      ...assocPozNeg,
    }

    return { current, associated }
  }

  return MOCK_FREQUENCY_DATA.map(({ period }) => ({
    period,
    ..._getForPeriod(period),
  }))
}
