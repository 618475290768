import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Accounts from 'components/accounts'
import { CheckboxNoHooks } from 'components/checkbox'
import useLeaveConfirm from 'components/leave-confirm'
import { AccountIcon } from 'components/account-icon'
import LineDivider from 'components/line-divider/index'
import Section from 'components/section/index'

import { SinglePerformanceApplyTo } from 'modules/alerts/single-performance-apply-to/index'

/* Constants */
import { ACCOUNT_TYPES } from 'modules/accounts/common'

import {
  DEFAULT_ELEMENTS_SINGLE,
  DEFAULT_ELEMENTS_MULTI,
} from 'modules/alerts/constants'

const ApplyToSection = ({
  accountsElements,
  accountsInSingleClient,
  activeAccountOptions,
  editField,
  errors,
  filteredTemplates,
  isPerformanceAlert,
  label,
  readOnlyCanToggleMode,
  state,
  setErrors,
  setState,
  singleCompanyAlert,
  publisherOnly,
}) => {
  const [setDirty] = useLeaveConfirm({})
  const hasSelectedAccounts = state.selectedAccounts?.length > 0
  const multipleCompaniesSelected =
    state.allCompaniesSelected || state.companies.length > 1

  const setCheckBoxValue = (account, value, checked) => {
    const selected = state.selectedElements[account] || { elements: [] }

    selected.elements = checked
      ? [...selected.elements, value]
      : selected.elements.filter((item) => item !== value)

    editField('selectedElements', {
      [account]: selected,
    })
  }

  /**
   * Callback for selecting accounts
   * @param {String[]} acts The list of selected accounts
   * @param {String} newAcc The newly selected account
   */
  const onSelectAccounts = (acts, newAcc) => {
    const add = acts.includes(newAcc)

    const removeFromProps = {}
    if (!add) {
      // Remove unselected account types
      const unselectedAccounts = Object.keys(state.properties || {})
        .filter((accountType) => acts.includes(accountType))
        .map((accountType) => ({
          [accountType]: state.properties[accountType],
        }))

      // Arrange the object
      Object.keys(unselectedAccounts).map((key) =>
        Object.keys(unselectedAccounts[key]).map(
          (item) => (removeFromProps[item] = unselectedAccounts[key][item])
        )
      )
    }

    if (isPerformanceAlert) {
      const newSelectedElements = { ...state.selectedElements }

      if (add) {
        newSelectedElements[newAcc] = singleCompanyAlert
          ? DEFAULT_ELEMENTS_SINGLE[newAcc]
          : DEFAULT_ELEMENTS_MULTI[newAcc]
      } else {
        delete newSelectedElements[newAcc]
      }

      setState({
        selectedAccounts: acts,
        selectedElements: { ...newSelectedElements },
      })
    } else {
      setState({
        selectedAccounts: acts,
        properties: add
          ? { ...state.properties, [newAcc]: [{}] }
          : removeFromProps,
      })
    }

    setErrors({ ...errors, selectedAccounts: null })
    setDirty(true)
  }

  // Switching between single- and multi-client state should reset the selections to avoid inconsistent data
  useEffect(() => {
    setState(
      {
        selectedElements: {},
      },
      true
    )
  }, [singleCompanyAlert])

  return (
    <section className="form__section">
      {label && <div className="form__section__header">{label}</div>}
      {/* Account Selection */}
      <Section
        header={
          <h3 className="generic-heading generic-heading--no-margin">
            {activeAccountOptions.length > 1
              ? 'Select Publishers'
              : 'Select Publisher'}
          </h3>
        }
      >
        <Accounts
          defaultSelected={state.selectedAccounts.filter((acc) =>
            activeAccountOptions.includes(acc)
          )}
          onSelect={onSelectAccounts}
          availableAccounts={activeAccountOptions}
          error={errors.selectedAccounts}
          returnKeys
          readOnly={readOnlyCanToggleMode}
        />
      </Section>
      {singleCompanyAlert && !multipleCompaniesSelected && (
        <SinglePerformanceApplyTo
          alert={state}
          onChangeAlert={setState}
          accounts={accountsInSingleClient}
          errors={errors}
          setErrors={setErrors}
          readOnly={readOnlyCanToggleMode}
        />
      )}
      {/* Apply to - Multiple clients */}
      {multipleCompaniesSelected &&
        hasSelectedAccounts &&
        isPerformanceAlert &&
        !publisherOnly &&
        state.selectedAccounts.map((acc, a) => {
          const foundType = ACCOUNT_TYPES.find((account) => account.id === acc)

          return (
            <div
              key={a}
              className="form__section__body form__section__body--no_padding align-column"
              style={{ marginTop: '30px' }}
            >
              <div className="alerts__granularities-header">
                <AccountIcon
                  accountType={foundType?.id}
                  className="alerts__granularities-header-icon"
                  alt={foundType?.name}
                />
                <p className="alerts__granularities-header-text">
                  Performance Alert Options
                </p>
              </div>
              <LineDivider
                exactWidth="calc(100% + 60px)"
                margin="0 auto 15px -30px"
              />
              <div className="align-row wrap" style={{ width: '100%' }}>
                {accountsElements[acc]?.map((item) => {
                  const isChecked = !!state.selectedElements[
                    acc
                  ]?.elements.find((elem) => elem === item.value)

                  return (
                    <div
                      style={{ width: '25%', marginBottom: '10px' }}
                      key={item.value}
                    >
                      <CheckboxNoHooks
                        key={item.value}
                        label={item?.label}
                        isChecked={isChecked}
                        disabled={item.disabled || readOnlyCanToggleMode}
                        onChange={() =>
                          setCheckBoxValue(acc, item.value, !isChecked)
                        }
                        className="checkbox-first"
                      />
                    </div>
                  )
                })}
              </div>
              {errors?.elements?.[acc] ? (
                <div className="error">{errors.elements[acc]}</div>
              ) : null}
            </div>
          )
        })}
    </section>
  )
}
ApplyToSection.propTypes = {
  accountsElements: PropTypes.object,
  accountsInSingleClient: PropTypes.object,
  activeAccountOptions: PropTypes.array,
  editField: PropTypes.func.isRequired,
  errors: PropTypes.object,
  filteredTemplates: PropTypes.array,
  isPerformanceAlert: PropTypes.bool,
  label: PropTypes.string,
  publisherOnly: PropTypes.bool,
  readOnlyCanToggleMode: PropTypes.bool,
  state: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  singleCompanyAlert: PropTypes.bool,
}

export default ApplyToSection
