import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Parser } from 'xml2js'

import { useStore } from 'store'
import { getTableauTicket } from 'modules/users/actions'
import './style.scss'

/**
 * Renderer for embedded Tableau reports
 * @param {Object} props Component props
 * @param {Object} props.reporting Embedded report configuration
 * @returns {React.FunctionComponent}
 */
const TableauReport = ({ reporting }) => {
  const { state } = useStore()
  const [authDone, setAuthDone] = useState(false)
  const [authTicket, setAuthTicket] = useState()
  const [error, setError] = useState(null)
  const [authenticationUrl, setAuthenticationUrl] = useState()

  const extractUrlFromEmbedCode = (embedCode) => {
    const parser = new Parser({
      ignoreAttrs: false,
    })
    let url = null
    parser.parseString(`<container>${embedCode}</container>`, (err, parsed) => {
      if (err) {
        console.log('Error parsing embedded code:', err)
        setError('Error parsing embedded code.')
      }
      url = parsed?.container?.['tableau-viz']?.[0]?.$?.src
    })

    return url
  }

  const extractReportConfig = (url) => {
    const regex = /\/t\/([^/]+)\/views\/([^/]+)\/([^/?]+)/
    const match = url.match(regex)

    if (!match) {
      setError('Error extracting report configuration.')
      return null
    }

    return {
      site: match[1],
      workbook: match[2],
      view: match[3],
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tkt = await getTableauTicket()
        if (tkt) {
          setAuthTicket(tkt)
          setError(null)
        } else {
          setError('Error getting Tableau authentication ticket.')
        }
      } catch (error) {
        setError('Error authenticating to Tableau.')
        console.log('Error getting Tableau authentication ticket.', error)
      } finally {
        setAuthDone(true)
      }
    }

    if (reporting && reporting.embedCode) {
      const embedUrl = extractUrlFromEmbedCode(reporting.embedCode)
      if (embedUrl) {
        const reportConfig = extractReportConfig(embedUrl)
        if (reportConfig) {
          if (reportConfig.site === state.global.environment.TABLEAU_SITE) {
            fetchData()
          } else {
            setError(
              `Emdedded report is configured for tableau site: ${
                reportConfig.site
              } instead of tableau site: ${'Qontrol_QA'}. `
            )
          }
        }
      }
    }
  }, [reporting])

  useEffect(() => {
    if (authDone && !error) {
      const embedUrl = extractUrlFromEmbedCode(reporting.embedCode)
      const urlSplit = embedUrl.split('/t/')
      const authenticationUrl = `${urlSplit[0]}/trusted/${authTicket}/t/${urlSplit[1]}`
      setAuthenticationUrl(authenticationUrl)
    }
  }, [authDone, error, authTicket])

  return (
    <section>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      {error && <div className="error">{error}</div>}
      {authDone === true && !error && authenticationUrl && (
        <iframe className="reporting-iframe" src={authenticationUrl} />
      )}
    </section>
  )
}

TableauReport.propTypes = {
  reporting: PropTypes.object.isRequired,
}

export { TableauReport }
